import { ENVIRONMENT } from '@monetize/ui/config';
import useResizeObserver from '@react-hook/resize-observer';
import type { PDFDocumentProxy } from 'pdfjs-dist/types/src/display/api';
import { FC, useCallback, useLayoutEffect, useRef, useState } from 'react';
import { MdOutlineFileDownload } from 'react-icons/md';
import { Document, Page, pdfjs } from 'react-pdf';
import { MCustomIconButton, MFlex, MPageLoader, MText } from '..';
import { handleApiErrorToast } from '../../../api/axios';
import { getSafeFilename } from '../../../utils';
import { downloadBlobAsFile } from '../../../utils/download';

pdfjs.GlobalWorkerOptions.workerSrc = ENVIRONMENT.PDFJS_WORKER_URL;

/**
 * Highlights text in PDF
 *
 * https://github.com/wojtekmaj/react-pdf/wiki/Recipes#highlight-text-on-the-page
 * https://gist.github.com/wojtekmaj/f265f55e89ccb4ce70fbd2f8c7b1d95d
 *
 * @param text
 * @param pattern
 * @returns
 */
function highlightPattern(text: string, pattern: string | RegExp): any {
  const splitText = text.split(pattern);
  if (splitText.length <= 1) {
    return text;
  }

  const matches = text.match(pattern);
  return splitText.reduce((arr: any[], element, index) => {
    return matches && matches[index]
      ? [...arr, element, <mark key={index}>{matches[index]}</mark>]
      : [...arr, element];
  }, []);
}

interface PreviewPdfProps {
  /** Filename to use when pdf is downloaded */
  filename: string;
  /**
   * Highlight text that matches provided regex
   * /Docusign tags: /\/m[0-9a-z]{3}\//i
   * https://monetizenow.atlassian.net/wiki/spaces/PE/pages/1159004161/Docusign+Tabs
   */
  highlightTextPattern?: string | RegExp;
  currentPdfFile: ArrayBuffer | null;
  showDownloadButton?: boolean;
}

/**
 * Side drawer for previewing PDF documents
 */
export const PreviewPdf: FC<PreviewPdfProps> = ({
  filename,
  highlightTextPattern,
  currentPdfFile,
  showDownloadButton,
}) => {
  const bodyRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);
  const [numPages, setNumPages] = useState<number | null>(null);
  const [size, setSize] = useState<DOMRect>();

  // If browser is resized, the PDF will be adjusted to fit the new size
  useResizeObserver(bodyRef, (entry) => setSize(entry.contentRect));

  useLayoutEffect(() => {
    if (bodyRef.current) {
      const roundingRect = bodyRef.current.getBoundingClientRect();
      roundingRect && setSize(roundingRect);
    }
  }, [bodyRef]);

  /**
   * Allows highlighting of text within PDF
   * https://github.com/wojtekmaj/react-pdf/wiki/Recipes#highlight-text-on-the-page
   */
  const textRenderer = useCallback(
    (
      textItem: {
        pageIndex: number;
        pageNumber: number;
        itemIndex: number;
      } & { str: string },
    ) => {
      if (!highlightTextPattern) {
        return textItem.str;
      }
      return highlightPattern(textItem.str, highlightTextPattern);
    },
    [highlightTextPattern],
  );

  const onDocumentLoadSuccess = (pdf: PDFDocumentProxy) => {
    setNumPages(pdf?.numPages || 0);
  };

  const downloadPdf = async () => {
    try {
      downloadBlobAsFile(currentPdfFile, `${getSafeFilename(filename)}.pdf`);
    } catch (err) {
      handleApiErrorToast(err);
    }
  };

  return (
    <MFlex
      position="relative"
      flex={1}
      backgroundColor="tGray.sidebarDark"
      ref={bodyRef}
    >
      <MFlex
        position="absolute"
        top={0}
        right={0}
        zIndex={1}
        w="100%"
        background="tGray.sidebarDark"
      >
        <MFlex my="2" w="100%" alignItems="center" mx="2">
          <MText
            fontSize="md"
            color="tPurple.500"
            fontWeight="bold"
            mr={6}
            flex="1"
          >
            {filename}
          </MText>

          {showDownloadButton && (
            <MCustomIconButton
              variant="icon"
              containerSize="6"
              btnSize={5}
              fontSize="lg"
              _focus={{ background: 'tGray.support' }}
              icon={MdOutlineFileDownload}
              onClick={() => downloadPdf()}
              color="tPurple.dark"
              iconColorHover="tPurple.base"
              mr={6}
              isDisabled={!currentPdfFile}
            />
          )}
        </MFlex>
      </MFlex>

      {!loading && !!currentPdfFile && (
        <MFlex flexDir="column" pt="6" overflowY="scroll">
          <Document
            loading={
              <MFlex justify="center" grow={1}>
                <MPageLoader />
              </MFlex>
            }
            file={currentPdfFile}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <MFlex key={`page_${index + 1}`} p={1} justify="center">
                <Page
                  customTextRenderer={textRenderer}
                  pageNumber={index + 1}
                  scale={1}
                  className="pdf-page"
                  width={size?.width ? size?.width - 40 : 1105}
                />
              </MFlex>
            ))}
          </Document>
        </MFlex>
      )}
    </MFlex>
  );
};
