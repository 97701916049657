import { BoxProps, Tab } from '@chakra-ui/react';
import {
  MBox,
  MTabList,
  MTabPanel,
  MTabPanels,
  MTabs,
  MText,
} from '../../../../../../components/Monetize';
import { useDealRoomContext } from '../../dealRoomContext';
import { QuoteOptionSectionSelectTable } from './QuoteOptionSectionSelectTable';
import { QuoteOptionSectionTab } from './QuoteOptionSectionTab';

interface QuoteOptionSectionProps extends BoxProps {}
export const QuoteOptionSection: React.FC<QuoteOptionSectionProps> = ({
  ...rest
}) => {
  const {
    dealRoomResp: { opportunity, quotes },
  } = useDealRoomContext();

  const orderedQuotes = quotes.sort((a, b) => a.position - b.position);

  return (
    <MBox w="full" {...rest}>
      <MText fontSize="3xl" color="tPurple.500" fontWeight="bold" mb="4">
        Quote Options
      </MText>

      <MTabs variant="line" size="sm" defaultIndex={0}>
        <MTabList pb={2}>
          <Tab>All Quotes</Tab>
          {orderedQuotes.map(({ quote, name }, index) => (
            <Tab key={quote.id}>{quote.description}</Tab>
          ))}
        </MTabList>
        <MTabPanels px="0" mx="0" p="0">
          <MTabPanel px="0" mx="0" py="0" my="0">
            <QuoteOptionSectionSelectTable />
          </MTabPanel>

          {orderedQuotes.map((quote, index) => (
            <MTabPanel key={`quote-panel-${quote.quote.id}`} px="0" mx="0">
              <QuoteOptionSectionTab dealRoomQuote={quote} />
            </MTabPanel>
          ))}
        </MTabPanels>
      </MTabs>
    </MBox>
  );
};
