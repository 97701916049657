import { forwardRef } from 'react';
import { MFileDragDropUpload } from '../../../../components/Monetize/MFileDragDropUpload';

export const LegalEntityLogoDropzone = forwardRef(
  (
    {
      value,
      onChange,
      ...rest
    }: {
      value?: File;
      onChange?: (file?: File) => void;
    },
    ref,
  ) => {
    const onDropAccepted = async (file: File) => {
      onChange?.(file);
    };

    return (
      <MFileDragDropUpload
        mt={4}
        accept={['png']}
        label="Drag and Drop your file here"
        onFileUpload={onDropAccepted}
        subtitle="This logo will display in 200px width"
        maxFileSize={10}
        // renderBoxContent={(props) => (
        //   <LegalEntityLogoUploadBoxContent
        //     companyLogo={value ? URL.createObjectURL(value) : ''}
        //     {...props}
        //   />
        // )}
        // resetErrorState={resetErrorState}
        innerContainerProps={{ p: '5' }}
      />
    );
  },
);
