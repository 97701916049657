import { BoxProps, useDisclosure } from '@chakra-ui/react';
import { useMemo } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { ContactFormModal } from '../../../../../../components/Contacts/ContactFormModal';
import {
  MBox,
  MCheckbox,
  MFormField,
  MGrid,
  MGridItem,
  MText,
} from '../../../../../../components/Monetize';
import { useACL } from '../../../../../../services/acl/acl';
import { DealRoomNextStepContact } from '../../../../../../types/dealroomTypes';
import { useDealRoomContacts } from '../../../../../hooks/useDealRoomContacts';
import { NextStepsSectionContactInfoContactInput } from './NextStepsSectionContactInfoContactInput';

interface NextStepsSectionContactInfoContactProps extends BoxProps {
  label: string;
  fieldName: 'billingId' | 'shippingId';
  contactForm: UseFormReturn<DealRoomNextStepContact>;
  dealRoomContacts: ReturnType<typeof useDealRoomContacts>;
  isDisabled: boolean;
  accountId: string;
}
export const NextStepsSectionContactInfoContact = ({
  label,
  fieldName,
  contactForm,
  dealRoomContacts,
  isDisabled,
  accountId,
  ...boxProps
}: NextStepsSectionContactInfoContactProps) => {
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = contactForm;
  const { loadingContacts, accountContacts } = dealRoomContacts;
  const contactsModalState = useDisclosure();
  const { canDo } = useACL();

  const watchValue = watch(fieldName);
  const foundContact = useMemo(
    () => accountContacts?.find((contact) => contact.id === watchValue),
    [watchValue, accountContacts],
  );

  const canCreateContact =
    canDo([['account_contacts', 'create']]) && !!accountId;

  return (
    <MBox {...boxProps}>
      <MGrid templateColumns={{ md: 'repeat(12, 1fr)' }} gap={4}>
        <MGridItem colSpan={12}>
          <MFormField label={label} w="100%" error={errors[fieldName]?.message}>
            <Controller
              name={fieldName}
              control={control}
              render={({ field: { value, onChange, ...field } }) => (
                <NextStepsSectionContactInfoContactInput
                  loading={loadingContacts}
                  contacts={accountContacts || []}
                  onChange={(val) => {
                    onChange(val);
                  }}
                  onNewContact={contactsModalState.onOpen}
                  canCreateContact={canCreateContact}
                  value={value || ''}
                  {...field}
                  isDisabled={isDisabled}
                  isReadOnly={isDisabled}
                  variant={isDisabled ? 'readonly' : 'primary'}
                />
              )}
            />
          </MFormField>
        </MGridItem>

        {contactsModalState.isOpen && (
          <ContactFormModal
            isOpen={contactsModalState.isOpen}
            defaultValues={{ internal: false }}
            accountId={accountId}
            onClose={contactsModalState.onClose}
            onCreatedContact={(contact) => {
              contactsModalState.onClose();
              setValue(fieldName, contact.id);
            }}
          />
        )}

        {foundContact && (
          <>
            <MGridItem colSpan={6}>
              <MFormField label="Name">
                <MText>{foundContact.fullName}</MText>
              </MFormField>
            </MGridItem>
            <MGridItem colSpan={6}>
              <MFormField label="Phone Number">
                <MText>{foundContact.phone}</MText>
              </MFormField>
            </MGridItem>
            <MGridItem colSpan={12}>
              <MFormField label="Email">
                <MText>{foundContact.email}</MText>
              </MFormField>
            </MGridItem>
          </>
        )}

        {fieldName === 'billingId' && (
          <MGridItem colSpan={12}>
            <MFormField w="100%">
              <Controller
                name="useSameShippingBilling"
                control={control}
                render={({ field: { value, onChange, ...field } }) => (
                  <MCheckbox
                    isChecked={!!value}
                    onChange={(e) => {
                      onChange(e.target.checked);
                      setValue('shippingId', value ? watch('billingId') : '');
                    }}
                    {...field}
                    isReadOnly={isDisabled}
                    isDisabled={isDisabled}
                  >
                    Use as Shipping Contact
                  </MCheckbox>
                )}
              />
            </MFormField>
          </MGridItem>
        )}
      </MGrid>
    </MBox>
  );
};
