import { useEffect, useState } from 'react';
import { handleApiErrorToast } from '../../../../api/axios';
import {
  useDealRoomConfig,
  useSaveDealRoomConfig,
} from '../../../../api/settingsService';
import {
  MBox,
  MLink,
  MPageContainer,
  MSettingsPageHeader,
  MSkeleton,
  MText,
  MVStack,
} from '../../../../components/Monetize';
import { useDocumentHead } from '../../../../services/documentHead';
import { IDealRoomSettingSchema } from '../../../../types/dealroomTypes';
import { ContractSettingSection } from '../QuoteSettings/components/ContractSettingSection';
import { SettingsToggle } from '../QuoteSettings/components/SettingsToggle';
import { DealRoomSettingSigner } from './components/DealRoomSettingSigner';
import { DealRoomSettingWelcome } from './components/DealRoomSettingWelcome';

export const DealRoomSettings = () => {
  const { setDocTitle } = useDocumentHead();
  useEffect(() => {
    setDocTitle('Settings', 'Deal Room');
  }, []);

  const { data: dealroomConfig, isLoading } = useDealRoomConfig();
  const { mutateAsync: doSaveDealRoomConfig } = useSaveDealRoomConfig();
  const [currentConfig, setCurrentConfig] = useState(dealroomConfig);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (dealroomConfig) {
      setCurrentConfig(dealroomConfig);
    }
  }, [dealroomConfig]);

  const handleSave = async (payload: IDealRoomSettingSchema): Promise<void> => {
    try {
      setIsSaving(true);
      setCurrentConfig(payload);
      await doSaveDealRoomConfig({
        ...currentConfig,
        ...payload,
      });
    } catch (ex) {
      handleApiErrorToast(ex);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <MPageContainer alignItems="stretch">
      <MBox>
        <MSettingsPageHeader divider title="Deal Room" />
      </MBox>

      <MBox w="full">
        <MVStack spacing={4} maxW="600px">
          <MText>
            Deal Rooms are virtual spaces for you to share Quotes, related
            Contract documents, and more, directly with your client.
            <MLink
              href="https://docs.monetizenow.io/docs/deal-room#/"
              ml="2"
              as="a"
              target="blank"
            >
              Learn More
            </MLink>
          </MText>

          {isLoading && (
            <MVStack maxW="600px" spacing={4}>
              <MSkeleton height={50} width="100%" />
              <MSkeleton height={50} width="100%" />
              <MSkeleton height={50} width="100%" />
            </MVStack>
          )}
          {!isLoading && currentConfig && (
            <>
              <ContractSettingSection showDivider={false}>
                <SettingsToggle
                  name="dealRoomCreation"
                  heading="Deal Room Creation"
                  subheading="Enable dealroom creation. "
                  fetchLoading={isLoading}
                  value={currentConfig.enabled}
                  onChange={(val) =>
                    handleSave({ ...currentConfig, enabled: val })
                  }
                />
              </ContractSettingSection>

              <DealRoomSettingWelcome
                value={{
                  useDefaultWelcomeMessage:
                    currentConfig.useDefaultWelcomeMessage,
                  defaultWelcomeMessage: currentConfig.defaultWelcomeMessage,
                  defaultWelcomeUrl: currentConfig.defaultWelcomeUrl,
                }}
                isLoading={isSaving}
                onSubmit={(val) => handleSave({ ...currentConfig, ...val })}
                isDisabled={!currentConfig.enabled}
              />

              {/* <DealRoomSettingMarketingRepository /> */}

              <DealRoomSettingSigner
                dealroomConfig={currentConfig}
                onSubmit={(val) => handleSave({ ...currentConfig, ...val })}
                isDisabled={!currentConfig.enabled}
              />
            </>
          )}
        </MVStack>
      </MBox>
    </MPageContainer>
  );
};
