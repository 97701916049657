import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  MDivider,
  MFlex,
  MFormField,
  MGrid,
  MGridItem,
  MRadio,
  MRadioGroup,
  MStack,
  MSwitch,
  MText,
} from '../../../../../../components/Monetize';
import { IContactRespSchema, SigningOrderEnum } from '../../../../../../types';
import {
  DealRoomNextStepEsignContact,
  DealRoomNextStepEsignContactSchema,
} from '../../../../../../types/dealroomTypes';
import { useDealRoomContext } from '../../dealRoomContext';
import { useDRSectionEditMode } from '../../useDRSectionEditMode';
import { NextStepsSectionContactInfoContactInput } from './NextStepsSectionContactInfoContactInput';
import { NextStepsSectionStep } from './NextStepsSectionStep';
import { NextStpesSectionAdditionalEsign } from './NextStpesSectionAdditionalEsign';

interface NextStepsSectionSignProps {}
export const NextStepsSectionSign: React.FC<NextStepsSectionSignProps> = () => {
  const { isDealRoomReadOnly, dealRoomContacts, dealRoomResp } =
    useDealRoomContext();

  const {
    handleSaveContacts,
    isContactsSaving,
    loadingContacts,
    internalAccountContacts,
    externalAccountContacts,
    esignContacts,
    loadingInternalEsignContacts,
  } = dealRoomContacts;

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors, isDirty, isValid },
    watch,
    setValue,
    getValues,
  } = useForm<DealRoomNextStepEsignContact>({
    resolver: zodResolver(DealRoomNextStepEsignContactSchema),
    mode: 'onBlur',
    defaultValues: esignContacts,
  });
  const requiresEsignWatch = watch('requiresEsign');
  const esignCcContactsWatch = watch('esignCcContacts');
  const externalEsignContactIdWatch = watch('externalEsignContactId');
  const selectedContactIds: string[] = useMemo(() => {
    return [externalEsignContactIdWatch].filter(Boolean) as string[];
  }, [externalEsignContactIdWatch]);

  const onSubmit = async (data: DealRoomNextStepEsignContact) => {
    await handleSaveContacts({
      esignForm: data,
    });
    setIsEditing(false);
  };

  const { iconGroups, isEditing, setIsEditing } = useDRSectionEditMode({
    isReadOnly: isDealRoomReadOnly,
    isLoading: isContactsSaving,
    handleCancel: () => {
      reset(esignContacts);
      setIsEditing(false);
    },
  });

  useEffect(() => {
    if (!isEditing) {
      reset(esignContacts);
    }
  }, [esignContacts, isEditing]);
  const isDisabled = !isEditing || isContactsSaving;

  const handleSelectedAdditionalRecipients = (contactId: string) => {
    const contact = externalAccountContacts.find(({ id }) => id === contactId);
    contact &&
      setValue('esignCcContacts', [
        ...esignCcContactsWatch,
        contact as IContactRespSchema,
      ]);
  };

  const handleCreatedNewAdditionalContact = (contact: IContactRespSchema) => {
    setValue('esignCcContacts', [
      ...esignCcContactsWatch,
      contact as IContactRespSchema,
    ]);
  };

  const handleAdditionalContactRemove = (contactId: string) => {
    setValue(
      'esignCcContacts',
      esignCcContactsWatch.filter(({ id }) => id !== contactId),
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <NextStepsSectionStep
        stepNumber={4}
        title="Sign:"
        labelRightContent={iconGroups.allIcons}
      >
        <MGrid templateColumns="repeat(12, 1fr)" columnGap={4} mt={2}>
          <MGridItem colSpan={12} mb="6">
            <MFormField error={errors?.requiresEsign}>
              <Controller
                name="requiresEsign"
                control={control}
                render={({ field: { value, onChange, ...rest } }) => (
                  <MFlex align="center">
                    <MText mr="2" fontSize="md" fontWeight="bold">
                      Allow eSign
                    </MText>
                    <MSwitch
                      isChecked={!!value}
                      onChange={(e) => onChange(e.target.checked)}
                      {...rest}
                      isDisabled={isDisabled}
                    />
                  </MFlex>
                )}
              />
              <MText mt="2">
                "Open eSign" will become active once a Quote is selected. If
                eSign is turned off, contact your seller to finalize the deal.
              </MText>
            </MFormField>
          </MGridItem>

          {requiresEsignWatch && (
            <>
              <MGridItem colSpan={6}>
                <MFormField label="Internal eSigner" w="100%">
                  <Controller
                    name={'internalEsignContactId'}
                    control={control}
                    render={({ field: { value, onChange, ...field } }) => (
                      <NextStepsSectionContactInfoContactInput
                        loading={loadingContacts}
                        contacts={internalAccountContacts || []}
                        onChange={(val) => {
                          onChange(val);
                        }}
                        onNewContact={() => {}}
                        canCreateContact={false}
                        value={value || ''}
                        isReadOnly={isDisabled}
                        isDisabled={isDisabled}
                        variant={isDisabled ? 'readonly' : 'primary'}
                        {...field}
                      />
                    )}
                  />
                </MFormField>
              </MGridItem>
              <MGridItem colSpan={6}>
                <MFormField label="External eSigner" w="100%">
                  <Controller
                    name={'externalEsignContactId'}
                    control={control}
                    render={({ field: { value, onChange, ...field } }) => (
                      <NextStepsSectionContactInfoContactInput
                        loading={loadingContacts}
                        contacts={externalAccountContacts || []}
                        onChange={(val) => {
                          onChange(val);
                        }}
                        onNewContact={() => {}}
                        canCreateContact={false}
                        value={value || ''}
                        isReadOnly={isDisabled}
                        isDisabled={isDisabled}
                        variant={isDisabled ? 'readonly' : 'primary'}
                        {...field}
                      />
                    )}
                  />
                </MFormField>
              </MGridItem>

              <MGridItem colSpan={12}>
                <MDivider my="2" variant="thick" borderColor="tGray.back" />
              </MGridItem>

              <MGridItem colSpan={12}>
                <NextStpesSectionAdditionalEsign
                  isDisabled={isDisabled}
                  accountId={dealRoomResp.accountId}
                  loadingContacts={loadingInternalEsignContacts}
                  contacts={externalAccountContacts}
                  selectedContacts={esignCcContactsWatch}
                  selectedContactIds={selectedContactIds}
                  onSelectedContactsChange={handleSelectedAdditionalRecipients}
                  handleCreatedNewContact={handleCreatedNewAdditionalContact}
                  onRemoveAdditionalContact={handleAdditionalContactRemove}
                />
              </MGridItem>
              <MGridItem colSpan={12}>
                <MDivider my="2" variant="thick" borderColor="tGray.back" />
              </MGridItem>

              <MGridItem colSpan={12} mb="6">
                <MFormField error={errors?.signingOrder}>
                  <MText mr="2" fontSize="md" fontWeight="bold" mb="2">
                    eSign Behavior
                  </MText>
                  <Controller
                    name="signingOrder"
                    control={control}
                    render={({ field: { value, onChange, ...rest } }) => (
                      <MRadioGroup
                        onChange={(e) => {
                          onChange(e as SigningOrderEnum);
                        }}
                        isDisabled={isDisabled}
                        value={value}
                        {...rest}
                      >
                        <MStack columnGap={5}>
                          <MRadio value={SigningOrderEnum.EXTERNAL_FIRST}>
                            Send to{' '}
                            {dealRoomResp?.accountName
                              ? dealRoomResp?.accountName
                              : 'external account contact'}{' '}
                            first
                          </MRadio>
                          <MRadio value={SigningOrderEnum.INTERNAL_FIRST}>
                            Send to{' '}
                            {dealRoomResp?.fromCompany?.name ||
                              'internal contact'}{' '}
                            first
                          </MRadio>
                          <MRadio value={SigningOrderEnum.INSTANTANEOUS}>
                            Send to both contacts at the same time
                          </MRadio>
                          <MRadio value={SigningOrderEnum.EXTERNAL_ONLY}>
                            Only require signature from customer{' '}
                            <strong>
                              {dealRoomResp?.accountName
                                ? dealRoomResp?.accountName
                                : 'external account contact'}
                            </strong>{' '}
                          </MRadio>
                        </MStack>
                      </MRadioGroup>
                    )}
                  />
                </MFormField>
              </MGridItem>
            </>
          )}
        </MGrid>
      </NextStepsSectionStep>
    </form>
  );
};
