import { Td, Tr } from '@chakra-ui/react';
import { formatCurrency, toDateShort } from '@monetize/utils/core';
import { useMemo } from 'react';
import { FiExternalLink, FiMenu } from 'react-icons/fi';
import {
  MCheckbox,
  MCustomIconButton,
  MFlex,
  MIcon,
  MLink,
  MText,
} from '../../../../../../components/Monetize';
import { useMDraggable } from '../../../../../../components/Monetize/useMDraggable';
import { ROUTES } from '../../../../../../constants';
import {
  QuoteStatusEnumDisplay,
  QuoteStatusTagStyle,
} from '../../../../../../constants/quotes';
import { IOpportunityQuote } from '../../../../../../types';
import { DealRoomQuoteSelectRow } from '../../../../../../types/dealroomTypes';
import { getLengthStartEnd } from '../../../../../../utils/dates';

interface QuoteOptionSectionSelectTableRowProps {
  index: number;
  rowData: DealRoomQuoteSelectRow;
  allQuotes: IOpportunityQuote[];
  moveRow: (dragIndex: number, hoverIndex: number) => void;
  saveMoveChanges: () => void;
  formQuotes: DealRoomQuoteSelectRow[];
  onSelectUpdated: (quoteId: string, selected: boolean) => void;
  isEditing: boolean;
  allowedQuotesCount: number;
}

export const QuoteOptionSectionSelectTableRow: React.FC<
  QuoteOptionSectionSelectTableRowProps
> = ({
  index,
  rowData,
  moveRow,
  saveMoveChanges,
  allQuotes,
  formQuotes,
  onSelectUpdated,
  isEditing,
  allowedQuotesCount,
}: QuoteOptionSectionSelectTableRowProps) => {
  const { dragHandleRef, dropContainerRef, handlerId, drag, opacity } =
    useMDraggable({
      index,
      rowData,
      moveRow,
      saveMoveChanges,
      acceptType: 'DEAL_ROOM_QUOTE_OPTION',
    });

  const quote = useMemo(
    () => allQuotes.find(({ id }) => id === rowData.quoteId),
    [allQuotes, rowData.quoteId],
  );
  const selectedQuotesCount = useMemo(
    () => formQuotes.filter(({ selected }) => selected).length,
    [formQuotes],
  );

  if (!quote) {
    return null;
  }
  const { id, name, contractStartDate, contractEndDate, total, currency } =
    quote;

  return (
    <Tr
      key={id}
      ref={dropContainerRef}
      opacity={opacity}
      data-handler-id={handlerId}
    >
      <Td p="1" textAlign={'left'}>
        {rowData.selected && isEditing && (
          <MCustomIconButton
            ref={dragHandleRef}
            cursor="move"
            btnSize={4}
            _hover={{
              background: 'none',
            }}
            icon={FiMenu}
            isDisabled={!isEditing}
          />
        )}
        <MCheckbox
          ml={rowData.selected && isEditing ? 9 : 14}
          isChecked={rowData.selected}
          isDisabled={
            (!rowData.selected && selectedQuotesCount >= allowedQuotesCount) ||
            !isEditing
          }
          onChange={() => onSelectUpdated(rowData.quoteId, !rowData.selected)}
        />
      </Td>
      <Td textAlign="left">
        <MFlex alignItems="center">
          <MText isTruncated>{name}</MText>

          <MLink
            href={ROUTES.getQuoteReviewRoute(rowData.quoteId)}
            isExternal
            fontWeight="bold"
            color="tIndigo.base"
            noOfLines={1}
          >
            <MIcon as={FiExternalLink} ml="2" color="tIndigo.base" />
          </MLink>
        </MFlex>
      </Td>
      <Td textAlign="left">
        <MText
          {...QuoteStatusTagStyle[quote.status]}
          data-testid="quote-tag-status"
        >
          {QuoteStatusEnumDisplay[quote.status]}
        </MText>
      </Td>
      <Td textAlign="left">
        {contractStartDate && contractEndDate && (
          <MText>
            {getLengthStartEnd(contractStartDate, contractEndDate)}{' '}
          </MText>
        )}
      </Td>
      <Td>
        {contractStartDate && contractEndDate && (
          <MText>
            {toDateShort(contractStartDate)} - {toDateShort(contractEndDate)}
          </MText>
        )}
      </Td>
      <Td textAlign="right">
        <MText>{formatCurrency(total, { currency })}</MText>
      </Td>
    </Tr>
  );
};
