import { BoxProps } from '@chakra-ui/react';
import { BsFilePdf } from 'react-icons/bs';
import { RiLink } from 'react-icons/ri';

import { MBox, MFlex, MIcon, MText } from '../../../../../components/Monetize';
import { DealRoomDocumentSectionContent } from '../../../../../types/dealroomTypes';

interface ContentListItemButtonProps extends BoxProps {
  data: DealRoomDocumentSectionContent;
  isActive: boolean;
  setCurrentContent: (content: DealRoomDocumentSectionContent) => void;
}

const ICONS = {
  ATTACHMENT: BsFilePdf,
  URL: RiLink,
};
export const ContentListItemButton: React.FC<ContentListItemButtonProps> = ({
  data,
  isActive,
  setCurrentContent,
  ...rest
}: ContentListItemButtonProps) => {
  if (data.contentType !== 'ATTACHMENT' && data.contentType !== 'URL') {
    return;
  }

  return (
    <MBox
      width="100%"
      p="4"
      background={isActive ? 'tBlue.100' : 'white'}
      borderRadius={2}
      cursor={'pointer'}
      {...rest}
      onClick={() => setCurrentContent(data)}
    >
      <MFlex flex="1" justifyContent={'space-between'} alignItems={'center'}>
        <MText fontSize={'xl'} fontWeight="500" isTruncated mr="3">
          {data.name || ''}
        </MText>

        {ICONS[data.contentType] && <MIcon as={ICONS[data.contentType]} />}
      </MFlex>
      <MText isTruncated noOfLines={1}>
        {data.description || ''}
      </MText>
    </MBox>
  );
};
