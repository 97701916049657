import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { Controller, useForm, useFormContext } from 'react-hook-form';
import { z } from 'zod';
import {
  MBox,
  MFormField,
  MInput,
  MText,
} from '../../../../../../components/Monetize';
import { DealRoomReq } from '../../../../../../types/dealroomTypes';
import { useDealRoomContext } from '../../dealRoomContext';
import { useDRSectionEditMode } from '../../useDRSectionEditMode';
import { NextStepsSectionStep } from './NextStepsSectionStep';

interface NextStepsSectionPONumberProps {}
export const NextStepsSectionPONumber: React.FC<
  NextStepsSectionPONumberProps
> = ({}: NextStepsSectionPONumberProps) => {
  const { handleSubmitButton, isDealRoomReadOnly, isDRSaving } =
    useDealRoomContext();
  const formContext = useFormContext<DealRoomReq>();
  const purchaseOrderNumberWatch =
    formContext.getValues('purchaseOrderNumber') || '';

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<{ content: string }>({
    resolver: zodResolver(z.object({ content: z.string().nonempty() })),
    mode: 'onBlur',
    defaultValues: { content: purchaseOrderNumberWatch },
  });

  const onSubmit = async (data) => {
    formContext.setValue('purchaseOrderNumber', data.content, {
      shouldDirty: true,
      shouldValidate: true,
    });
    await handleSubmitButton();
    setIsEditing(false);
  };

  const { iconGroups, isEditing, setIsEditing } = useDRSectionEditMode({
    isReadOnly: isDealRoomReadOnly,
    isLoading: isDRSaving,
    handleCancel: () => {
      reset({ content: purchaseOrderNumberWatch });
      setIsEditing(false);
    },
    useIconButtons: true,
  });

  useEffect(() => {
    if (!isEditing) {
      reset({ content: purchaseOrderNumberWatch });
    }
  }, [purchaseOrderNumberWatch, isEditing]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <NextStepsSectionStep
        stepNumber={2}
        title="Provide PO Number"
        labelRightContent={iconGroups.allIcons}
        maxW="300px"
      >
        <MBox>
          <MFormField error={errors.content} label="PO Number">
            {isEditing ? (
              <Controller
                name="content"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <MInput placeholder="PO Number" {...field} />
                )}
              />
            ) : (
              <MBox fontSize="md">
                {purchaseOrderNumberWatch && (
                  <MText>{purchaseOrderNumberWatch}</MText>
                )}
              </MBox>
            )}
          </MFormField>
        </MBox>
      </NextStepsSectionStep>
    </form>
  );
};
