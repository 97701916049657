import { Container, ContainerProps } from '@chakra-ui/react';
import { MBox, MVStack } from '../../../../../components/Monetize';
import { DEAL_ROOM_SECTION_IDS } from '../../../../constants/dealroom';
import { DealRoomTopBar } from './DealRoomTopBar';
import { ExtraInfoSection } from './components/ExtraInfoSection';
import { FeatureCaseSection } from './components/FeatureCaseSection';
import { NextStepsSection } from './components/NextStepsSection';
import { QuoteOptionSection } from './components/QuoteOptionSection';
import { WelcomeSection } from './components/WelcomeSection';

interface DealRoomContainerProps {}
export const DealRoomContainer: React.FC<DealRoomContainerProps> = ({}) => {
  const containerProps: ContainerProps = {
    minHeight: '100vh',
    centerContent: true,
    width: 'auto',
    px: ['4px', '4px', '32px'],
    pt: ['24px', '24px', '32px'],
    maxWidth: '1440px',
  };

  return (
    <MBox background="tGray.dealroomBack">
      <DealRoomTopBar />

      <Container {...containerProps} pb="100">
        <MVStack spacing={12} w="full">
          <WelcomeSection
            id={DEAL_ROOM_SECTION_IDS.DR_WELCOME}
            className="dr-section"
          />
          <FeatureCaseSection
            id={DEAL_ROOM_SECTION_IDS.DR_FEATURE_CASE}
            className="dr-section"
          />
          <QuoteOptionSection
            id={DEAL_ROOM_SECTION_IDS.DR_QUOTE_OPTION}
            className="dr-section"
          />
          <ExtraInfoSection
            id={DEAL_ROOM_SECTION_IDS.DR_EXTRA_INFO}
            className="dr-section"
          />
          <NextStepsSection
            id={DEAL_ROOM_SECTION_IDS.DR_NEXT_STEPS}
            className="dr-section"
          />
        </MVStack>
      </Container>
    </MBox>
  );
};
