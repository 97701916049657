import { MBox, MDivider, MText } from '../../../../../components/Monetize';

interface NotificationItemProps {}

export const NotificationItem: React.FC<NotificationItemProps> = ({}) => {
  return (
    <MBox w="full">
      <MText color="tWhite.base" fontSize="md">
        Quote 3 was Created
      </MText>
      <MText color="tWhite.base">Quote 1 Created by Joe Jonas</MText>
      <MDivider color={'tWhite.base'} />
    </MBox>
  );
};
