import { IOfferingSummaryResSchema, RateSummary } from '@monetize/types/app';
import { Maybe } from '@monetize/types/common';
import { toDateShort } from '@monetize/utils/core';
import { useMemo } from 'react';
import {
  MCustomMultiSelect,
  MCustomSelect,
  MFlex,
  MFormField,
  MText,
} from '../../../../../components/Monetize';
import { MainSearchInputV2 } from '../../../../../components/Monetize/MCustomSelect/components/MainSearchInputV2';
import { Highlighter } from '../../../../../components/Monetize/MPageSearchInput/Highlighter';
import {
  RATE_BILLING_FREQUENCY_MAP,
  RATE_STATUS_MODEL,
  SUBSCRIPTION_TIMING_OPTIONS,
} from '../../../../../constants/offerings';
import { OfferingStatusDisplayText } from '../../../../../constants/onboarding';

interface ImportRateSelectionProps {
  sourceOfferingId?: Maybe<string>;
  selectedOfferingId: string | null;
  rateIds: string[];
  onOfferingIdChange: (value: string | null) => void;
  onRateIdChange: (value: string[]) => void;
}

export const ImportRateSelection = ({
  sourceOfferingId,
  selectedOfferingId,
  rateIds,
  onOfferingIdChange,
  onRateIdChange,
}: ImportRateSelectionProps) => {
  const offeringSearchParams = useMemo(() => {
    const params = { sort: 'name:asc' };
    if (sourceOfferingId) {
      params['id'] = `ne:${sourceOfferingId}`;
    }
    return params;
  }, [sourceOfferingId]);

  const rateSearchParams = useMemo(
    () => ({ offeringId: selectedOfferingId, sort: 'name:asc' }),
    [selectedOfferingId],
  );

  return (
    <>
      <MText>Import rates from offerings with similar products.</MText>
      <MText>
        Products that don't exist on this offering will be ignored. You will
        need to provide prices for newly added products.
      </MText>
      <MFormField label="Select Offering" mt={3}>
        <MCustomSelect
          isLazy
          itemTitle="name"
          itemValue="id"
          placeholder="Search Offerings"
          useMainInputAsSearchInput
          MainInputComponent={MainSearchInputV2}
          endpoint="api/offerings/summary"
          additionalSearchParams={offeringSearchParams}
          value={selectedOfferingId}
          renderItemContent={OfferingItemContentRenderer}
          onChange={(val: any) => {
            onOfferingIdChange(val);
          }}
        />
      </MFormField>

      {/* 
        We need to be careful here that we don't fetch all rates in tenant
        which is why we don't render until we have the offering id
       */}
      {selectedOfferingId && (
        <MFormField label="Select Rates" mt={3}>
          <MCustomMultiSelect
            showQueryInput
            isDisabled={!selectedOfferingId}
            itemTitle="name"
            itemValue="id"
            placeholder="Select Rates"
            closeButtonText="Select Rates"
            endpoint="/api/rates"
            additionalSearchParams={rateSearchParams}
            value={rateIds}
            onChange={(value) => onRateIdChange(value)}
            renderItemContent={RateContentRenderer}
            isLazy
            multiple
            mt={0}
          />
        </MFormField>
      )}
    </>
  );
};

const OfferingItemContentRenderer = ({
  item,
  query,
}: {
  item: IOfferingSummaryResSchema;
  query: string;
}) => {
  const { id, status, name, modifyDate } = item;

  return (
    <MFlex
      direction="column"
      overflow="hidden"
      whiteSpace="nowrap"
      textOverflow="ellipsis"
      w="100%"
    >
      <MFlex justifyContent="space-between">
        <Highlighter
          title={name}
          color="tPurple.dark"
          fontSize="sm"
          fontWeight="medium"
          textToHighlight={name}
          searchWords={[query]}
        />
        <MText color="tGray.darkPurple" fontSize="xs">
          {OfferingStatusDisplayText[status]}
        </MText>
      </MFlex>
      <MFlex justifyContent="space-between" w="100%">
        <MText color="tGray.darkPurple" fontSize="xs">
          {id}
        </MText>
        <MText color="tGray.darkPurple" fontSize="xs">
          last Modified {toDateShort(modifyDate)}
        </MText>
      </MFlex>
    </MFlex>
  );
};

const RateContentRenderer = ({
  item,
  query,
}: {
  item: RateSummary;
  query: string;
}) => {
  const {
    billingFrequency,
    billingFrequencyInMonths,
    currency,
    id,
    name,
    status,
    subscriptionTiming,
  } = item;

  return (
    <MFlex
      direction="column"
      overflow="hidden"
      whiteSpace="nowrap"
      textOverflow="ellipsis"
      w="100%"
    >
      <MFlex justifyContent="space-between">
        <Highlighter
          title={name}
          color="tPurple.dark"
          fontSize="sm"
          fontWeight="medium"
          textToHighlight={name}
          searchWords={[query]}
        />
        <MText color="tGray.darkPurple" fontSize="xs">
          {RATE_STATUS_MODEL[status]}
        </MText>
      </MFlex>
      <MFlex justifyContent="space-between" w="100%">
        <MText color="tGray.darkPurple" fontSize="xs">
          {id}
        </MText>
        <MText color="tGray.darkPurple" fontSize="xs">
          {currency}
          {' / '}
          {
            RATE_BILLING_FREQUENCY_MAP[billingFrequency](
              billingFrequencyInMonths,
            ).label
          }
          {' / '}
          {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
          {SUBSCRIPTION_TIMING_OPTIONS[subscriptionTiming!]}
        </MText>
      </MFlex>
    </MFlex>
  );
};
