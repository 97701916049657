import { useDisclosure } from '@chakra-ui/react';
import { MdSearch } from 'react-icons/md';
import {
  MBox,
  MButton,
  MCenterModal,
  MFlex,
  MIcon,
  MRadio,
  MText,
} from '../../../../../../components/Monetize';
import { DealRoomQuoteResp } from '../../../../../../types/dealroomTypes';
import { DealRoomCard } from '../../../../../components/DealRoom/DealRoomCard';
import { QuoteOptionSelectHtml } from './QuoteOptionSelectHtml';

interface NextStepsSectionSelectQuoteItemProps {
  dealRoomQuote: DealRoomQuoteResp;
  isSelected: boolean;
  isEditable: boolean;
  onChange: (quoteId: string) => void;
}
export const NextStepsSectionSelectQuoteItem: React.FC<
  NextStepsSectionSelectQuoteItemProps
> = ({
  dealRoomQuote,
  isSelected,
  isEditable,
  onChange,
}: NextStepsSectionSelectQuoteItemProps) => {
  const {
    isOpen: isQuoteModalOpen,
    onOpen: onOpenQuoteModal,
    onClose: onCloseQuoteModal,
  } = useDisclosure();

  return (
    <DealRoomCard
      width="100%"
      p="4"
      border={isSelected ? '3px solid' : 'none'}
      borderColor={'tBlue.400'}
      display="flex"
      flexDir="column"
      cursor={!isEditable ? 'default' : 'pointer'}
      onClick={() => isEditable && onChange(dealRoomQuote.quote.id)}
    >
      <MFlex alignItems="center" mb="4">
        <MRadio isChecked={isSelected} />
        <MText fontWeight="bold" fontSize="md" noOfLines={1} isTruncated ml="2">
          {dealRoomQuote.name}
        </MText>
      </MFlex>

      <MText fontSize="md" whiteSpace="pre-wrap" flex="1">
        {dealRoomQuote.note}
      </MText>

      <MBox mt="2">
        <MButton variant="tertiary" onClick={onOpenQuoteModal}>
          <MIcon as={MdSearch} fontSize="16" mr="2" />
          Quick view
        </MButton>
      </MBox>

      {isQuoteModalOpen && (
        <MCenterModal
          isOpen={isQuoteModalOpen}
          onClose={onCloseQuoteModal}
          size="5xl"
        >
          <MBox>
            <QuoteOptionSelectHtml quoteId={dealRoomQuote.quote.id} />
          </MBox>
        </MCenterModal>
      )}
    </DealRoomCard>
  );
};
