import { BoxProps } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { Controller, useForm, useFormContext } from 'react-hook-form';
import {
  MBox,
  MFlex,
  MFormField,
  MInput,
  MText,
  MTextarea,
} from '../../../../../components/Monetize';
import {
  DealRoomDocumentSection,
  DealRoomDocumentSectionKeyEnum,
  DealRoomDocumentSectionSchema,
  DealRoomReq,
} from '../../../../../types/dealroomTypes';
import {
  getSectionFromSections,
  setSectionFromSections,
} from '../../../../utils/dealRoomUtils';
import { useDealRoomContext } from '../dealRoomContext';
import { useDRSectionEditMode } from '../useDRSectionEditMode';

interface DealRoomContentListEditorHeaderProps extends BoxProps {
  sectionKey: DealRoomDocumentSectionKeyEnum;
  allowEditTitle?: boolean;
}
export const DealRoomContentListEditorHeader: React.FC<
  DealRoomContentListEditorHeaderProps
> = ({
  sectionKey,
  allowEditTitle,
  ...rest
}: DealRoomContentListEditorHeaderProps) => {
  const {
    handleSubmitButtonWithoutDirtyCheck,
    isDealRoomReadOnly,
    isDRSaving,
  } = useDealRoomContext();
  const formContext = useFormContext<DealRoomReq>();
  const sectionsWatch = formContext.getValues('document.document.sections');

  const sectionWatch = getSectionFromSections(sectionsWatch, sectionKey);

  const sectionForm = useForm<DealRoomDocumentSection>({
    resolver: zodResolver(DealRoomDocumentSectionSchema),
    mode: 'onBlur',
    defaultValues: sectionWatch,
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = sectionForm;

  useEffect(() => {
    reset(sectionWatch);
  }, [sectionWatch]);

  const onSubmit = async (data: DealRoomDocumentSection) => {
    const newSections = setSectionFromSections(sectionsWatch, sectionKey, {
      ...sectionWatch,
      // Only replace title and description
      title: data.title,
      description: data.description,
    });
    formContext.setValue('document.document.sections', newSections, {
      shouldDirty: true,
      shouldValidate: true,
    });
    await handleSubmitButtonWithoutDirtyCheck();
    setIsEditing(false);
  };

  const { iconGroups, isEditing, setIsEditing } = useDRSectionEditMode({
    isReadOnly: isDealRoomReadOnly,
    isLoading: isDRSaving,
    handleCancel: () => {
      reset(sectionWatch);
      setIsEditing(false);
    },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {!isEditing ? (
        <MBox mb="4">
          <MFlex justifyContent="space-between" alignItems="center" mb="2">
            <MText fontSize="3xl" fontWeight="bold">
              {sectionWatch.title}
            </MText>
            {allowEditTitle && iconGroups.editIcon}
          </MFlex>
          <MText fontSize="mx" fontWeight="bold" mb="4">
            {sectionWatch.description}
          </MText>
        </MBox>
      ) : (
        <MBox mb="4">
          <MFormField label="Section Name" error={errors?.title} mb="4">
            <Controller
              name="title"
              control={control}
              render={({ field: { value, ...field } }) => (
                <MInput
                  fontSize="3xl"
                  fontWeight="bold"
                  height="50px"
                  value={value || ''}
                  {...field}
                />
              )}
            />
          </MFormField>
          <MFormField
            label="Section Description"
            error={errors?.description}
            mb="4"
          >
            <Controller
              name="description"
              control={control}
              render={({ field: { value, ...field } }) => (
                <MTextarea value={value || ''} {...field} />
              )}
            />
          </MFormField>
          <MFlex alignContent="center" justifyContent="flex-end">
            {iconGroups.saveIcon}
          </MFlex>
        </MBox>
      )}
    </form>
  );
};
