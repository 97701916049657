import { IOfferingRes, OfferingStatusEnum } from '@monetize/types/app';
import { useNavigate } from 'react-router';
import { apiPost } from '../../../../api/axios';
import { usePerformEntityAction } from '../../../../api/queryUtils';
import { MDataTableActions } from '../../../../components/Monetize';
import { ROUTES } from '../../../../constants';
import { useSettingsReadOnlyRule } from '../../../../hooks/useSettingsReadOnlyRule';

interface OfferingProps {
  offering: IOfferingRes;
  source: 'LIST' | 'DETAIL';
}

export const OfferingActions = ({ offering, source }: OfferingProps) => {
  const navigate = useNavigate();
  const { canCreateSettings } = useSettingsReadOnlyRule();

  // using mutateAsync so we can show a loading indicator on the menu while action is executing
  const { mutateAsync: doActivateDeactivate } =
    usePerformEntityAction<IOfferingRes>('productCatalogOfferings', {
      meta: { showErrorToast: true },
    });

  const { mutateAsync: doArchiveRestore } =
    usePerformEntityAction<IOfferingRes>('productCatalogOfferings', {
      apiMethodOverride: apiPost,
      meta: { showErrorToast: true },
    });

  const { id, name, status } = offering;

  if (!id || !canCreateSettings) {
    return null;
  }

  const confirmProps = {
    title: `Are you sure you want to deactivate "${name}"?`,
    description: 'Once deactivated, this offering will no longer be available.',
  };
  const archiveConfirmProps = {
    title: `Are you sure you want to archive "${name}"?`,
    description: 'Once archived, this offering will no longer be available.',
  };

  const actions = [
    {
      title: 'Activate',
      enabled: source === 'LIST' && status === OfferingStatusEnum.INACTIVE,
      action: () => doActivateDeactivate({ id, action: 'activate' }),
    },
    {
      title: 'Clone',
      enabled: true,
      action: () => id && navigate(ROUTES.getOfferingCreateRoute(id)),
    },
    {
      title: 'Restore',
      enabled: status === OfferingStatusEnum.ARCHIVED,
      action: () => doArchiveRestore({ id, action: 'restore' }),
    },
    {
      title: 'Deactivate',
      color: 'danger',
      confirmProps: {
        ...confirmProps,
        yesButton: 'Deactivate',
      },
      enabled: source === 'LIST' && status === OfferingStatusEnum.ACTIVE,
      action: () => doActivateDeactivate({ id, action: 'deactivate' }),
    },
    {
      title: 'Archive',
      color: 'danger',
      confirmProps: archiveConfirmProps,
      enabled: status === OfferingStatusEnum.INACTIVE,
      action: () => doArchiveRestore({ id, action: 'archive' }),
    },
  ];

  return <MDataTableActions actions={actions} />;
};
