import { ButtonProps, useDisclosure } from '@chakra-ui/react';
import {
  IProduct,
  IRateReqSchemaUI,
  IRateResSchema,
  OfferingTypesEnum,
} from '@monetize/types/app';
import { Maybe } from '@monetize/types/common';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { handleApiErrorToast } from '../../../../../api/axios';
import { doGetOfferingRate } from '../../../../../api/productCatalogService';
import {
  MButton,
  MCenterModal,
  MStack,
} from '../../../../../components/Monetize';
import { logger } from '../../../../../services/logger';
import { convertRateResToReq } from '../../../../../utils/rates';
import { ImportRateSelection } from './ImportRateSelection';

interface RateImportRow {
  rateId: string;
  originalRate: IRateResSchema;
  newRate: IRateReqSchemaUI;
  status: 'importing' | 'completed' | 'failed';
}

interface ImportRateModalProps {
  offeringId?: Maybe<string>;
  buttonProps?: ButtonProps;
  isDisabled?: boolean;
  offeringType: OfferingTypesEnum;
  products: IProduct[];
  onOpen: () => boolean;
  onImportRates: (rates: IRateReqSchemaUI[]) => void;
}

export const ImportRateModal = ({
  offeringId,
  buttonProps,
  isDisabled,
  offeringType,
  products,
  onOpen: canOpenModal,
  onImportRates,
}: ImportRateModalProps) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const [selectedOfferingId, setSelectedOfferingId] = useState<string | null>(
    null,
  );
  const [rateIds, setRateIds] = useState<string[]>([]);

  const [isLoading, setIsLoading] = useState(false);

  const handleOfferingChange = (value: string | null) => {
    setSelectedOfferingId(value);
    setRateIds([]);
  };

  const handleImport = async () => {
    try {
      setIsLoading(true);
      const productIds = new Set(products.map((product) => product.id));
      const rates: IRateResSchema[] = [];

      for (const rateId of rateIds) {
        rates.push(await doGetOfferingRate(rateId));
      }

      const newRates = rates.map((rate): RateImportRow => {
        rate = {
          ...rate,
          prices: rate.prices.filter((price) =>
            productIds.has(price.product.id),
          ),
        };
        const newRate = convertRateResToReq(
          rate,
          products,
          rate.currency,
          offeringType,
        );
        newRate.id = `clone-import-${uuidv4()}`;
        return {
          rateId: rate.id,
          originalRate: rate,
          newRate,
          status: 'importing',
        };
      });

      logger.log('Imported rates', newRates);
      onImportRates(newRates.map(({ newRate }) => newRate));
      onClose();
    } catch (ex) {
      logger.error('Error importing rates', ex);
      handleApiErrorToast(ex);
    } finally {
      setIsLoading(false);
    }
  };

  const toggleOpen = (value: boolean) => {
    setSelectedOfferingId(null);
    setRateIds([]);
    if (value) {
      if (canOpenModal()) {
        onOpen();
      }
    } else {
      onClose();
    }
  };

  return (
    <>
      <MButton
        data-testid="import-rate-btn"
        isDisabled={isDisabled}
        variant="tertiary"
        fontSize="sm"
        onClick={() => toggleOpen(true)}
        {...buttonProps}
      >
        + Import Rates From Existing Offering
      </MButton>
      {isOpen && (
        <MCenterModal
          size="xl"
          isOpen
          onClose={onClose}
          modalTitle={`Import Rates From Existing Offering`}
          modalHeaderProps={{ pb: 1 }}
          renderFooter={() => (
            <MStack
              spacing={4}
              direction="row"
              align="center"
              justify="right"
              flex={1}
            >
              <MButton
                variant="cancel"
                onClick={() => toggleOpen(false)}
                minW="auto"
              >
                Cancel
              </MButton>
              <MButton
                variant="primary"
                isDisabled={!selectedOfferingId || rateIds.length === 0}
                isLoading={isLoading}
                onClick={() => handleImport()}
                minW="auto"
              >
                Import Rates
              </MButton>
            </MStack>
          )}
        >
          <ImportRateSelection
            sourceOfferingId={offeringId}
            selectedOfferingId={selectedOfferingId}
            rateIds={rateIds}
            onOfferingIdChange={handleOfferingChange}
            onRateIdChange={setRateIds}
          />
        </MCenterModal>
      )}
    </>
  );
};
