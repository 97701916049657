import { Link } from 'react-router-dom';
import { MBox, MFlex, MIcon, MLink } from '../../../../../components/Monetize';

interface DealRoomSideBarMenuItemProps {
  icon?: any;
  showBullet?: boolean;
  bulletColor?: string;
  label: string;
  iconColor: string;
  showLine?: boolean;
  to?: string;
  onClick?: () => void;
}

export const DealRoomSideBarMenuItem: React.FC<
  DealRoomSideBarMenuItemProps
> = ({
  icon,
  showBullet,
  label,
  iconColor,
  bulletColor,
  showLine,
  to,
  onClick,
}) => {
  const hoverColor = 'tIndigo.base';
  const color = 'tWhite.base';

  return (
    <MFlex
      w="full"
      minH="40px"
      my="3"
      alignItems="center"
      role="group"
      cursor="pointer"
    >
      <MBox
        minW="4"
        mr="2"
        display="flex"
        alignItems="center"
        position="relative"
      >
        {icon && (
          <MIcon
            as={icon}
            justifyContent="flex-start"
            color={iconColor}
            w={5}
            h={5}
            _groupHover={{ color: hoverColor, fontWeight: 'semibold' }}
          />
        )}
        {showBullet && (
          <MBox
            w="2"
            h="2"
            borderRadius="50%"
            backgroundColor={bulletColor}
            display="flex"
            alignItems="center"
            justifyContent="center"
            _groupHover={{
              backgroundColor: hoverColor,
            }}
            zIndex={10}
          />
        )}
        {showLine && (
          <MBox
            position="absolute"
            left="3px"
            right="0"
            top="50%"
            w="2px"
            h="55px"
            backgroundColor={iconColor}
          />
        )}
      </MBox>
      <MFlex align="center" justify="space-between" flex="1">
        <MLink
          alignSelf="center"
          color={color}
          _groupHover={{
            color: hoverColor,
          }}
          fontSize="md"
          variant="nodecoration"
          to={to}
          as={to ? Link : undefined}
          onClick={onClick}
        >
          {label}
        </MLink>
      </MFlex>
    </MFlex>
  );
};
