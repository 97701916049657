import { FlexProps } from '@chakra-ui/react';
import React from 'react';
import { MBox, MFlex, MText } from '../../../../../../components/Monetize';

interface NextStepsSectionStepProps extends FlexProps {
  stepNumber: number;
  title: string;
  children?: React.ReactElement;
  labelRightContent?: React.ReactNode;
}
export const NextStepsSectionStep: React.FC<NextStepsSectionStepProps> = ({
  stepNumber,
  title,
  children,
  labelRightContent,
  ...rest
}) => {
  return (
    <MFlex w="full" justifyContent="space-between" mb="8" {...rest}>
      <MBox w="48px">
        <MText fontSize="xl" color="tPurple.500" fontWeight="bold">
          {stepNumber}.
        </MText>
      </MBox>
      <MBox flex="1">
        <MFlex justifyContent="space-between" alignItems="center" mb="6">
          <MText fontSize="xl" color="tPurple.500" fontWeight="bold">
            {title}
          </MText>
          <MBox>{labelRightContent}</MBox>
        </MFlex>

        {children}
      </MBox>
    </MFlex>
  );
};
