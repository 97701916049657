import { BoxProps } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { Controller, useForm, useFormContext } from 'react-hook-form';
import { MdLink } from 'react-icons/md';
import {
  MBox,
  MDivider,
  MFlex,
  MFormField,
  MIcon,
  MInput,
  MLink,
  MText,
  MTextarea,
} from '../../../../../../components/Monetize';
import { MClipboardCopyBox } from '../../../../../../components/Monetize/MClipboardCopyBox';
import MEditableInput from '../../../../../../components/Monetize/MEditableInput';
import {
  DealRoomDocumentSectionKeyEnum,
  DealRoomReq,
  DealRoomWelcome,
  DealRoomWelcomeSchema,
} from '../../../../../../types/dealroomTypes';
import {
  composeWelcomeSection,
  extractWelcomeSectionValue,
  setSectionFromSections,
} from '../../../../../utils/dealRoomUtils';
import { useDealRoomContext } from '../../dealRoomContext';
import { useDRSectionEditMode } from '../../useDRSectionEditMode';

interface WelcomeSectionProps extends BoxProps {}
export const WelcomeSection: React.FC<WelcomeSectionProps> = ({ ...rest }) => {
  const { handleSubmitButton, isDealRoomReadOnly, dealRoomResp, isDRSaving } =
    useDealRoomContext();
  const formContext = useFormContext<DealRoomReq>();
  const sectionsWatch = formContext.getValues('document.document.sections');

  const welcomeSectionWatch = extractWelcomeSectionValue(sectionsWatch);

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<DealRoomWelcome>({
    resolver: zodResolver(DealRoomWelcomeSchema),
    mode: 'onBlur',
    defaultValues: { ...welcomeSectionWatch },
  });

  const handleEdit = () => {};

  const onSubmit = async (data: DealRoomWelcome) => {
    const newSections = setSectionFromSections(
      sectionsWatch,
      DealRoomDocumentSectionKeyEnum.WELCOME,
      composeWelcomeSection(data),
    );
    formContext.setValue('document.document.sections', newSections, {
      shouldDirty: true,
      shouldValidate: true,
    });
    await handleSubmitButton();
    setIsEditing(false);
  };

  const { iconGroups, isEditing, setIsEditing } = useDRSectionEditMode({
    isReadOnly: isDealRoomReadOnly,
    isLoading: isDRSaving,
    handleEdit,
    handleCancel: () => {
      reset(welcomeSectionWatch);
      setIsEditing(false);
    },
  });

  useEffect(() => {
    if (!isEditing) {
      reset(welcomeSectionWatch);
    }
  }, [sectionsWatch, isEditing]);

  return (
    <MBox
      boxShadow="dealroomCard"
      bg="white"
      w="100%"
      py="8"
      px="6"
      borderRadius="8px"
      position="relative"
      {...rest}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <MBox position="absolute" right="6" top="6">
          {iconGroups.allIcons}
        </MBox>

        <MFlex alignItems="center" marginRight="130px">
          <Controller
            defaultValue=""
            name="title"
            control={control}
            render={({ field: { onChange, value, onBlur, ref, ...rest } }) => (
              <MEditableInput
                aria-label="title"
                value={value || ''}
                onSubmit={(newValue) => {
                  onChange(newValue);
                }}
                submitOnBlur
                isDisabled={!isEditing}
                onChange={onChange}
                onBlur={onBlur}
                {...rest}
                previewProps={{
                  fontSize: '5xl',
                  fontWeight: '600',
                  px: 1,
                }}
                inputProps={{
                  value: value || '',
                  fontSize: '5xl',
                  fontWeight: '600',
                  height: '72px',
                  ...rest,
                }}
              />
            )}
          />
        </MFlex>
        <MFlex mt="6" alignItems="flex-start">
          <MBox maxW={['100px', '200px']}>
            <MText
              color="tPurple.500"
              fontWeight="semibold"
              fontSize="md"
              noOfLines={1}
            >
              {dealRoomResp.owner?.name}
            </MText>
            <MLink
              href={`mailto:${dealRoomResp.owner?.email}`}
              target="_blank"
              isTruncated
              fontSize={'sm'}
              noOfLines={1}
            >
              {dealRoomResp.owner?.email}
            </MLink>
          </MBox>
          <MBox height="auto" alignSelf="stretch">
            <MDivider
              orientation="vertical"
              mx="6"
              height="100%"
              minHeight="1px"
              alignSelf="stretch"
            />
          </MBox>
          <MBox flex="1">
            <MBox mb="4">
              {isEditing ? (
                <MFormField
                  label="Introduction Message"
                  error={errors?.message}
                >
                  <Controller
                    name="message"
                    control={control}
                    render={({ field }) => (
                      <MTextarea
                        {...field}
                        placeholder="Personalize your introductory message here."
                      />
                    )}
                  />
                </MFormField>
              ) : (
                <MText>
                  {welcomeSectionWatch.message ||
                    'No introductory message yet.'}
                </MText>
              )}
            </MBox>
            <MBox>
              {isEditing ? (
                <>
                  <MFormField label="Link" error={errors?.link}>
                    <Controller
                      name="link"
                      control={control}
                      render={({ field }) => (
                        <MInput {...field} placeholder="https://" />
                      )}
                    />
                  </MFormField>
                  <MFormField
                    label="Link Label (optional)"
                    error={errors?.linkLabel}
                    mt="4"
                  >
                    <Controller
                      name="linkLabel"
                      control={control}
                      render={({ field: { value, ...field } }) => (
                        <MInput value={value || ''} {...field} />
                      )}
                    />
                  </MFormField>
                </>
              ) : (
                <MBox>
                  {welcomeSectionWatch.link && (
                    <MFlex alignItems="center">
                      <MLink
                        fontSize="sm"
                        fontWeight="normal"
                        href={welcomeSectionWatch.link}
                        target={'_blank'}
                        display="flex"
                        alignItems="center"
                      >
                        <MText color="tIndigo.base">
                          {welcomeSectionWatch.linkLabel ||
                            welcomeSectionWatch.link}
                        </MText>
                      </MLink>
                      <MClipboardCopyBox
                        copyValue={welcomeSectionWatch.link}
                        tooltipMessage="Copy URL"
                        renderContent={() => {
                          return (
                            <MIcon
                              verticalAlign="middle"
                              as={MdLink}
                              boxSize={4}
                              color="tPurple.dark"
                              cursor="pointer"
                              ml="2"
                            />
                          );
                        }}
                      />
                    </MFlex>
                  )}
                </MBox>
              )}
            </MBox>
          </MBox>
        </MFlex>
      </form>
    </MBox>
  );
};
