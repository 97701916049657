import { BoxProps } from '@chakra-ui/react';
import { MBox, MFlex } from '../../../../../components/Monetize';
import { DealRoomDocumentSectionKeyEnum } from '../../../../../types/dealroomTypes';
import { DealRoomContentList } from './DealRoomContentList';
import { DealRoomContentListEditorHeader } from './DealRoomContentListEditorHeader';

interface DealRoomContentListEditorProps extends BoxProps {
  sectionKey: DealRoomDocumentSectionKeyEnum;
  allowEditTitle?: boolean;
}
export const DealRoomContentListEditor: React.FC<
  DealRoomContentListEditorProps
> = ({
  sectionKey,
  allowEditTitle,
  ...rest
}: DealRoomContentListEditorProps) => {
  return (
    <MBox w="full" {...rest} position="relative">
      <DealRoomContentListEditorHeader
        sectionKey={sectionKey}
        allowEditTitle={allowEditTitle}
      />
      <MFlex w="100%">
        <DealRoomContentList sectionKey={sectionKey} />
      </MFlex>
    </MBox>
  );
};
