import { ColumnProps } from 'primereact/column';
import { DataTableRowClickEvent } from 'primereact/datatable';
import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { useGetListData } from '../../../api/queryUtils';
import {
  MDataTable,
  MHStack,
  MPageContainer,
  shouldOpenInNewWindow,
} from '../../../components/Monetize';
import {
  DateGlteTableFilterOptionContent,
  MDataTableFilter,
} from '../../../components/Monetize/DataTable';
import { ExportTableButton } from '../../../components/Monetize/ExportEntityButton';
import { MSearchInput } from '../../../components/Monetize/MPageSearchInput/MSearchInput';
import { ROUTES } from '../../../constants';
import { OFFERING_STATUS_DISPLAY } from '../../../constants/offerings';
import { getOfferingEditRoute } from '../../../constants/routes';
import { useSettingsReadOnlyRule } from '../../../hooks/useSettingsReadOnlyRule';
import {
  tableFilterSelector,
  tablePagerSelector,
} from '../../../store/global.store';
import { FilterStateKeys } from '../../../store/store.types';
import {
  DEFAULT_PAGER,
  FilterOptionType,
  FilterType,
  FilterTypeOperator,
  GetListApiFilter,
  IOfferingRes,
  IOfferingSummaryResSchema,
  OfferingStatusEnum,
} from '../../../types';
import {
  buildFilterParamsRequestObject,
  getFiltersApplied,
  getIsTrulyEmptyList,
  transformTableFilterValue,
} from '../../../utils';
import { objectToObjArray } from '../../../utils/misc';
import {
  dateBodyTemplate,
  dateTimeBodyTemplate,
  idBodyTemplate,
  statusBodyTemplate,
  textBodyTemplate,
} from '../../../utils/tableUtils';
import PageHeader from '../components/PageHeader';
import TableHeader from '../components/TableHeader';
import { OfferingActions } from './components/OfferingActions';

const OfferingListPage: FC = () => {
  const navigate = useNavigate();
  const [initialFilters, persistTableFilter] = useRecoilState(
    tableFilterSelector(FilterStateKeys.OFFERING_LIST),
  );

  const [pager, setPager] = useRecoilState(
    tablePagerSelector(FilterStateKeys.OFFERING_LIST),
  );
  // this is temporary until BE refactor to show archived
  const [filters, setFilters] = useState<FilterType[]>(initialFilters);
  const [searchKey] = useState<string>('name');
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [filterParams, setFilterParams] = useState<GetListApiFilter>(() =>
    buildFilterParamsRequestObject(
      transformTableFilterValue(filters),
      searchTerm,
      searchKey,
    ),
  );

  useEffect(() => {
    persistTableFilter(filters);
  }, [filters, persistTableFilter]);

  const {
    data: offeringList,
    isLoading,
    isFetched,
    isRefetching,
    refetch,
    isError,
    error,
  } = useGetListData<IOfferingSummaryResSchema>('productCatalogOfferings', {
    config: pager,
    filters: {
      ...filterParams,
    },
  });

  const loading = isLoading || (isRefetching && !isFetched);
  const isTrulyEmptyList = getIsTrulyEmptyList({
    loading,
    totalElements: offeringList?.totalElements || 0,
    filters,
    searchTerm,
    page: pager.page,
  });

  const onResetFilter = () => {
    setFilters([]);
  };

  useEffect(() => {
    setFilterParams(
      buildFilterParamsRequestObject(
        transformTableFilterValue(filters),
        searchTerm,
        searchKey,
      ),
    );
  }, [pager, searchTerm, searchKey, filters]);

  const { canCreateSettings } = useSettingsReadOnlyRule();

  const columns: ColumnProps[] = [
    {
      className: ' overflow-hidden table-cell-md',
      field: 'id',
      header: 'ID',
      body: idBodyTemplate<IOfferingRes>('id'),
    },
    {
      field: 'name',
      header: 'Name',
      sortable: true,
      body: textBodyTemplate<IOfferingRes>('name'),
    },
    {
      field: 'description',
      header: 'Description',
      body: textBodyTemplate<IOfferingRes>('description'),
      className: 'table-cell-lg',
    },
    {
      field: 'startDate',
      header: 'Start Date',
      sortable: true,
      body: dateBodyTemplate<IOfferingRes>('startDate'),
    },
    {
      field: 'endDate',
      header: 'End Date',
      sortable: true,
      body: dateBodyTemplate<IOfferingRes>('endDate'),
    },
    {
      field: 'status',
      header: 'Status',
      sortable: true,
      body: statusBodyTemplate<IOfferingRes, OfferingStatusEnum>(
        'status',
        OFFERING_STATUS_DISPLAY,
      ),
    },
    {
      field: 'modifyDate',
      header: 'Updated',
      sortable: true,
      body: dateTimeBodyTemplate<IOfferingRes>('modifyDate'),
      className: 'table-cell-sm',
    },
    {
      body: (offering) => <OfferingActions offering={offering} source="LIST" />,
    },
  ];

  const handleOnRowClick = (e: DataTableRowClickEvent) => {
    shouldOpenInNewWindow(e)
      ? window.open(getOfferingEditRoute(e.data?.id), '_blank')
      : navigate(getOfferingEditRoute(e.data?.id));
  };

  const statusItems = objectToObjArray(
    (({ ACTIVE, INACTIVE, CANCELED, ARCHIVED }) => ({
      ACTIVE,
      INACTIVE,
      CANCELED,
      ARCHIVED,
    }))(OFFERING_STATUS_DISPLAY),
    'value',
    'label',
  );

  const filterOptions: FilterOptionType[] = [
    {
      title: 'Status',
      key: 'status',
      operator: FilterTypeOperator.IN,
      items: statusItems,
    },
    {
      title: 'Created',
      key: 'createDate',
      operator: FilterTypeOperator.GLTE,
      renderOptionContent: (props) => (
        <DateGlteTableFilterOptionContent showTimePicker {...props} />
      ),
    },
    {
      title: 'Updated',
      key: 'modifyDate',
      operator: FilterTypeOperator.GLTE,
      renderOptionContent: (props) => (
        <DateGlteTableFilterOptionContent showTimePicker {...props} />
      ),
    },
  ];

  const hasFiltersOrSearchApplied =
    getFiltersApplied(filters) > 0 || !!searchTerm;

  const filterComponentReset = React.useRef<any>(null);
  const searchComponentReset = React.useRef<any>(null);

  return (
    <MPageContainer>
      <PageHeader />

      <TableHeader
        model="Offering"
        showCreateNewBtn={!isTrulyEmptyList && canCreateSettings}
      >
        {!isTrulyEmptyList && (
          <MHStack spacing="2">
            <MSearchInput
              placeholder="Search Offerings"
              apiKey="offeringSearch"
              searchKey="query"
              searchOperator="equals"
              nameField="name"
              sortField="name"
              value={searchTerm || ''}
              onChange={setSearchTerm}
              generateRoute={ROUTES.getOfferingEditRoute}
              onReset={() => {
                onResetFilter();
                setPager(DEFAULT_PAGER);
              }}
              clearable
            />

            <MDataTableFilter
              filters={filters}
              filterOptions={filterOptions}
              setFilters={(f) => setFilters && setFilters(f)}
              onResetFilter={onResetFilter}
              resetFilter={filterComponentReset}
            />
            <ExportTableButton
              entity="offerings"
              filters={filters}
              searchKey={searchKey}
              searchTerm={searchTerm}
              sortField={pager.sortField}
              sortOrder={pager.sortOrder}
            />
          </MHStack>
        )}
      </TableHeader>

      <MDataTable
        value={offeringList?.content}
        totalRecords={offeringList?.totalElements}
        totalPages={offeringList?.totalPages}
        pager={pager}
        setPager={setPager}
        rowHover
        singleEmptyPlaceholder
        emptyFilterResultsProps={{
          mainMessage: 'Looks like there are no offerings here.',
          smallMessage: null,
          btnLabel: 'New Offering',
          to: ROUTES.OFFERING_NEW,
          secondaryBtnLabel: hasFiltersOrSearchApplied
            ? 'Clear filters'
            : undefined,
        }}
        className="p-datatable-responsive"
        loading={loading}
        columns={columns}
        filtersApplied={hasFiltersOrSearchApplied}
        resetFilter={() => {
          filterComponentReset.current && filterComponentReset.current();
          searchComponentReset.current && searchComponentReset.current();
        }}
        onRowClick={handleOnRowClick}
        errorProps={{
          isFetchingError: isError,
          error: error,
          onRefetchData: refetch,
        }}
      />
    </MPageContainer>
  );
};

export default OfferingListPage;
