import { BoxProps } from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import {
  MBox,
  MButton,
  MDropdownActionItem,
  MDropdownActions,
  MFlex,
  MText,
} from '../../../../../components/Monetize';
import {
  DealRoomDocumentSectionContent,
  DealRoomDocumentSectionKeyEnum,
  DealRoomReq,
} from '../../../../../types/dealroomTypes';
import {
  getSectionFromSections,
  setSectionFromSections,
} from '../../../../utils/dealRoomUtils';
import { useDealRoomContext } from '../dealRoomContext';
import { AttachmentContent } from './AttachmentContent';
import { ContentListItemButton } from './ContentListItemButton';
import { UrlContent } from './UrlContent';

interface DealRoomContentListProps extends BoxProps {
  sectionKey: DealRoomDocumentSectionKeyEnum;
}
export const DealRoomContentList: React.FC<DealRoomContentListProps> = ({
  sectionKey,
  ...rest
}) => {
  const { handleSubmitButton } = useDealRoomContext();
  const formContext = useFormContext<DealRoomReq>();
  const sectionsWatch = formContext.getValues('document.document.sections');
  const sectionWatch = getSectionFromSections(sectionsWatch, sectionKey);

  const [currentContent, setCurrentContent] =
    useState<DealRoomDocumentSectionContent | null>(null);
  const isEditing = useMemo(
    () =>
      !!currentContent &&
      !!sectionWatch.contents?.find(({ key }) => key === currentContent?.key),
    [currentContent, sectionWatch.contents],
  );
  const isCreating = useMemo(
    () =>
      !!currentContent &&
      !sectionWatch.contents?.find(({ key }) => key === currentContent?.key),
    [currentContent, sectionWatch.contents],
  );

  useEffect(() => {
    if (sectionWatch.contents?.length > 0 && !currentContent) {
      setCurrentContent(sectionWatch.contents[0]);
    }
  }, [sectionWatch.contents]);

  const onNewAttachment = () => {
    setCurrentContent({
      key: `attachment-${uuidv4()}`,
      contentType: 'ATTACHMENT',
      name: '',
      description: '',
      content: { storageObjectId: '' },
    });
  };

  const onNewUrl = () => {
    setCurrentContent({
      key: `url-${uuidv4()}`,
      contentType: 'URL',
      name: '',
      description: '',
      content: { link: '', label: '' },
    });
  };

  const handleDeleteContent = async (
    content: DealRoomDocumentSectionContent,
  ) => {
    const newContents = sectionWatch.contents?.filter(
      ({ key }) => key !== content.key,
    );
    const newSections = setSectionFromSections(sectionsWatch, sectionKey, {
      ...sectionWatch,
      contents: newContents,
    });
    formContext.setValue('document.document.sections', newSections, {
      shouldDirty: true,
      shouldValidate: true,
    });
    await handleSubmitButton();
  };

  const handleSaveContent = async (content: DealRoomDocumentSectionContent) => {
    const newContents = isEditing
      ? sectionWatch.contents?.map((c) => (c.key === content.key ? content : c))
      : [...(sectionWatch.contents || []), content];

    const newSections = setSectionFromSections(sectionsWatch, sectionKey, {
      ...sectionWatch,
      contents: newContents,
    });
    formContext.setValue('document.document.sections', newSections, {
      shouldDirty: true,
      shouldValidate: true,
    });
    await handleSubmitButton();
    setCurrentContent(content);
  };

  return (
    <MBox w="full" {...rest}>
      <MFlex w="100%">
        <MFlex flexDirection={'column'} w="250px" mr="4" gap="2">
          {sectionWatch.contents?.map((content, index) => (
            <ContentListItemButton
              data={content}
              isActive={content.key === currentContent?.key}
              setCurrentContent={setCurrentContent}
              key={`content-${index}`}
            />
          ))}

          <MDropdownActions
            popOverContentProps={{ minW: '250px' }}
            popoverProps={{ matchWidth: true }}
            renderTrigger={() => (
              <MButton variant="secondary" w="250px" isDisabled={isCreating}>
                + New
              </MButton>
            )}
          >
            {({ onClose }) => (
              <>
                <MDropdownActionItem
                  onClick={() => {
                    onNewAttachment();
                    onClose();
                  }}
                >
                  <MText color="inherit">File Upload</MText>
                </MDropdownActionItem>
                <MDropdownActionItem
                  onClick={() => {
                    onNewUrl();
                    onClose();
                  }}
                >
                  <MText color="inherit">Link</MText>
                </MDropdownActionItem>
              </>
            )}
          </MDropdownActions>
        </MFlex>

        <MBox flex="1" maxW="100%" minW="0">
          {!currentContent && sectionWatch.contents.length === 0 && (
            <MText fontSize="md" mt="1">
              No information added yet. This section is not visible to end
              customers until information has been added.{' '}
            </MText>
          )}
          {currentContent?.contentType === 'URL' && (
            <UrlContent
              currentContent={currentContent}
              key={currentContent.key}
              handleDeleteContent={handleDeleteContent}
              setCurrentContent={setCurrentContent}
              handleSaveContent={handleSaveContent}
              isCreating={isCreating}
            />
          )}
          {currentContent?.contentType === 'ATTACHMENT' && (
            <AttachmentContent
              currentContent={currentContent}
              key={currentContent.key}
              handleDeleteContent={handleDeleteContent}
              setCurrentContent={setCurrentContent}
              handleSaveContent={handleSaveContent}
              isCreating={isCreating}
            />
          )}
        </MBox>
      </MFlex>
    </MBox>
  );
};
