import { forwardRef, Ref, SVGProps } from 'react';

export const AnalyticsIcon = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(
  (props, ref: Ref<SVGSVGElement>) => {
    return (
      <svg
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M8.83203 0C4.43203 0 0.832031 3.6 0.832031 8C0.832031 12.4 4.43203 16 8.83203 16C13.232 16 16.832 12.4 16.832 8C16.832 3.6 13.232 0 8.83203 0ZM9.63203 1.656C12.52 2.016 14.816 4.32 15.176 7.2H9.63203V1.656ZM2.43203 8C2.43203 4.752 4.88803 2.048 8.03203 1.656V14.352C4.88803 13.952 2.43203 11.248 2.43203 8ZM9.63203 14.344V8.8H15.176C14.816 11.688 12.52 13.984 9.63203 14.344Z"
          fill="#4659DA"
        />
      </svg>
    );
  },
);
