import { useParams } from 'react-router';
import { DealRoomContextProvider } from './components/dealRoomContext';
import { InternalDealRoomPageContent } from './components/InternalDealRoomPageContent';

export const InternalDealRoomPage = () => {
  const { dealRoomId } = useParams() || {};

  return (
    <DealRoomContextProvider dealRoomId={dealRoomId!}>
      <InternalDealRoomPageContent />
    </DealRoomContextProvider>
  );
};
