import { BoxProps } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { handleApiErrorToast } from '../../../../../api/axios';
import {
  MBox,
  MFlex,
  MFormField,
  MInput,
  MSkeleton,
  MText,
} from '../../../../../components/Monetize';
import { MFileDragDropUpload } from '../../../../../components/Monetize/MFileDragDropUpload';
import { PreviewStorageFile } from '../../../../../components/Monetize/PreviewFile/PreviewStorageFile';
import {
  DealRoomAttachmentContent,
  DealRoomAttachmentContentSchema,
} from '../../../../../types/dealroomTypes';
import { createFileFromIStorage } from '../../../../../utils';
import {
  doUploadAttachment,
  useGetAttachmentByStorageId,
} from '../../../../api/dealRoomService';
import { useDealRoomContext } from '../dealRoomContext';
import { useDRSectionEditMode } from '../useDRSectionEditMode';

interface AttachmentContentProps extends BoxProps {
  currentContent: DealRoomAttachmentContent;
  handleDeleteContent: (content: DealRoomAttachmentContent) => void;
  setCurrentContent: (content: DealRoomAttachmentContent | null) => void;
  handleSaveContent: (content: DealRoomAttachmentContent) => void;
  isCreating?: boolean;
}
export const AttachmentContent: React.FC<AttachmentContentProps> = ({
  currentContent,
  handleDeleteContent,
  setCurrentContent,
  isCreating,
  handleSaveContent,
  ...rest
}: AttachmentContentProps) => {
  const { handleSubmitButton, isDealRoomReadOnly, dealRoomResp, isDRSaving } =
    useDealRoomContext();
  const { data: attachmentResp, isLoading: isAttachmentLoading } =
    useGetAttachmentByStorageId(
      dealRoomResp.id,
      currentContent.content.storageObjectId,
      {
        enabled: !!currentContent.content.storageObjectId && !isCreating,
      },
    );

  const [isUploading, setIsUploading] = useState(false);
  const [attachmentFile, setAttachmentFile] = useState<File | null>(null);
  const [newFileUploaded, setNewFileUploaded] = useState(false);

  const onDropAccepted = useCallback(async (acceptedFiles: File) => {
    setAttachmentFile(acceptedFiles);
    setNewFileUploaded(true);
  }, []);

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<DealRoomAttachmentContent>({
    resolver: zodResolver(DealRoomAttachmentContentSchema),
    mode: 'onBlur',
    defaultValues: { ...currentContent },
  });

  const onSubmit = async (data: DealRoomAttachmentContent) => {
    try {
      if (attachmentFile && newFileUploaded) {
        setIsUploading(true);

        const { id } = await doUploadAttachment(
          dealRoomResp.id,
          attachmentFile,
        );
        data.content.storageObjectId = id;
        setIsUploading(false);
      }

      await handleSaveContent(data);
      setIsEditing(false);
    } catch (err) {
      handleApiErrorToast(err);
    }
  };

  const { iconGroups, isEditing, setIsEditing } = useDRSectionEditMode({
    isReadOnly: isDealRoomReadOnly,
    isLoading: isDRSaving || isUploading,
    handleCancel: () => {
      reset(currentContent);
      if (isCreating) {
        setCurrentContent(null);
      }
      setIsEditing(false);
    },
    handleEdit: () => {
      attachmentResp &&
        setAttachmentFile(createFileFromIStorage(attachmentResp));
    },
    showDeletedButton: true,
    handleDelete: async () => {
      await handleDeleteContent(currentContent);
      setCurrentContent(null);
    },
  });
  useEffect(() => {
    setIsEditing(isCreating || false);
  }, [isCreating]);

  useEffect(() => {
    if (!isEditing) {
      reset(currentContent);
    }
  }, [currentContent, isEditing]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {isEditing && (
        <MBox flex="1" {...rest}>
          <MFormField label="Item Title" error={errors?.name} isRequired>
            <Controller
              name={`name`}
              control={control}
              render={({ field: { value, ...field } }) => (
                <MInput value={value || ''} {...field} />
              )}
            />
          </MFormField>

          <MFormField
            mt={4}
            label="Item Description"
            error={errors?.description}
            isRequired
          >
            <Controller
              name={`description`}
              control={control}
              render={({ field: { value, ...field } }) => (
                <MInput value={value || ''} {...field} />
              )}
            />
          </MFormField>

          <MFormField mt={4} label="File" isRequired>
            <MFileDragDropUpload
              accept={['pdf', 'docx', 'xlsx', 'ppt']}
              label="Drag and Drop your file here or Browse"
              onFileUpload={onDropAccepted}
              maxFileSize={10}
              innerContainerProps={{ p: '5', bg: 'white' }}
              selectedFile={attachmentFile}
            />
          </MFormField>

          <MFlex mt={4} justifyContent={'space-between'}>
            {iconGroups.saveIcon}
          </MFlex>
        </MBox>
      )}

      {!isEditing && (
        <MBox>
          <MFlex alignItems="center">
            <MText
              fontSize={'xl'}
              fontWeight="500"
              mr="4"
              isTruncated
              title={currentContent.name || ''}
            >
              {currentContent.name || ''}
            </MText>
            <MFlex alignItems={'center'} gap={4}>
              {iconGroups.editIcon}
            </MFlex>
          </MFlex>

          <MText mt={2}>{currentContent.description || ''}</MText>

          {isAttachmentLoading && <MSkeleton mt={2} height="20px" />}
          {attachmentResp && (
            <MFlex maxH="600px">
              <PreviewStorageFile file={attachmentResp} mt={2} />
            </MFlex>
          )}
        </MBox>
      )}
    </form>
  );
};
