import { SigningOrderEnum } from '@monetize/types/app';
import { formatCurrency, toDateShort } from '@monetize/utils/core';
import { IOpportunityQuote } from '../../types';
import {
  DEAL_ROOM_WELCOME_MESSAGE_CONTENT_KEY,
  DEAL_ROOM_WELCOME_URL_CONTENT_KEY,
  DealRoomContactsResp,
  DealRoomDocumentSection,
  DealRoomDocumentSectionKeyEnum,
  DealRoomNextStepContact,
  DealRoomNextStepEsignContact,
  DealRoomQuoteItemReq,
  DealRoomQuoteSelectRow,
  DealRoomReq,
  DealRoomResp,
  DealRoomTextContent,
  DealRoomUrlContent,
  DealRoomWelcome,
} from '../../types/dealroomTypes';

export const getDealRoomReqFromResp = (
  dealRoomResp: DealRoomResp | null,
): Partial<DealRoomReq> => {
  if (!dealRoomResp) {
    return {};
  }

  const {
    id,
    customId,
    expirationDate,
    status,
    opportunity,
    fromCompany,
    purchaseOrderNumber,
    requiresEsign,
    selectedQuoteId,
    accountId,
    accountName,
    contacts,
    shippingAddress,
    billingAddress,
    signingOrder,
    document,
    quotes,
  } = dealRoomResp;

  return {
    customId,
    expirationDate,
    purchaseOrderNumber,
    requiresEsign,
    quotes: quotes.map((quote) => ({
      quoteId: quote.quote.id,
      customName: quote.name,
      note: quote.note,
      position: quote.position,
    })),
    selectedQuoteId,
    document,
    signingOrder,
  };
};

export const getSectionFromSections = (
  sections: DealRoomDocumentSection[],
  sectionKey: DealRoomDocumentSectionKeyEnum,
) => {
  return (
    (sections || []).find(({ key }) => key === sectionKey) || {
      key: sectionKey,
      contents: [],
    }
  );
};

export const setSectionFromSections = (
  sections: DealRoomDocumentSection[],
  sectionKey: DealRoomDocumentSectionKeyEnum,
  updatedSection: DealRoomDocumentSection,
) => {
  // find section from sections, if found replace ,if not add
  const newSections = [...sections];
  const sectionIndex = newSections.findIndex(({ key }) => key === sectionKey);
  if (sectionIndex > -1) {
    newSections[sectionIndex] = updatedSection;
  } else {
    newSections.push(updatedSection);
  }

  return newSections;
};

export const extractWelcomeSectionValue = (
  sections: DealRoomDocumentSection[],
): DealRoomWelcome => {
  const welcomeSection = (sections || []).find(
    ({ key }) => key === DealRoomDocumentSectionKeyEnum.WELCOME,
  );

  return {
    title: welcomeSection?.title || 'Welcome',
    message:
      (
        welcomeSection?.contents?.find(
          ({ key, contentType }) =>
            key === DEAL_ROOM_WELCOME_MESSAGE_CONTENT_KEY &&
            contentType === 'TEXT',
        ) as DealRoomTextContent
      )?.content || '',
    link:
      (
        welcomeSection?.contents?.find(
          ({ key, contentType }) =>
            key === DEAL_ROOM_WELCOME_URL_CONTENT_KEY && contentType === 'URL',
        ) as DealRoomUrlContent
      )?.content?.link || '',
    linkLabel:
      (
        welcomeSection?.contents?.find(
          ({ key, contentType }) =>
            key === DEAL_ROOM_WELCOME_URL_CONTENT_KEY && contentType === 'URL',
        ) as DealRoomUrlContent
      )?.content?.label || '',
  };
};

export const composeWelcomeSection = (
  welcomeSection: DealRoomWelcome,
): DealRoomDocumentSection => {
  return {
    title: welcomeSection.title,
    description: '',
    key: DealRoomDocumentSectionKeyEnum.WELCOME,
    contents: [
      {
        key: DEAL_ROOM_WELCOME_MESSAGE_CONTENT_KEY,
        contentType: 'TEXT',
        content: welcomeSection.message,
      },
      {
        key: DEAL_ROOM_WELCOME_URL_CONTENT_KEY,
        contentType: 'URL',
        content: {
          link: welcomeSection.link,
          label: welcomeSection.linkLabel || '',
        },
      },
    ],
  };
};

/**
 * Order current quotes first, and rest of quotes
 * @param allQuotes all quotes of the opportunity
 * @param selectedQuotes selected quotes in deal room
 * @returns ordered form array of position and quoteId
 */
export const getFormQuotesFromDRResp = (
  allQuotes: IOpportunityQuote[],
  selectedQuotes: DealRoomQuoteItemReq[],
) => {
  // Order current quotes first, and rest of quotes
  const selectedQuoteIds = selectedQuotes.map(({ quoteId }) => quoteId);

  return [
    ...selectedQuotes
      .sort((quote1, quote2) => quote1.position - quote2.position)
      .map(({ position, quoteId }) => ({
        position,
        quoteId,
        selected: true,
      })),
    ...allQuotes
      .filter(({ id }) => !selectedQuoteIds.includes(id))
      .map(({ id }) => ({ quoteId: id, position: null, selected: false })),
  ];
};

export const setDRReqFromFormQuote = (
  existingQuotes: DealRoomQuoteItemReq[],
  formQuotes: DealRoomQuoteSelectRow[],
  allQuotes: IOpportunityQuote[],
) => {
  // Find only selected quotes and order them by position
  return formQuotes
    .filter(({ selected }) => !!selected)
    .map(({ quoteId }, index) => {
      const existingQuote = existingQuotes.find(
        ({ quoteId: id }) => id === quoteId,
      );
      const foundQuoteFromAllQuote = allQuotes.find(({ id }) => id === quoteId);

      return {
        quoteId,
        position: index + 1,
        customName:
          existingQuote?.customName || foundQuoteFromAllQuote?.name || '',
        note:
          existingQuote?.note ||
          (foundQuoteFromAllQuote
            ? `
- ${toDateShort(foundQuoteFromAllQuote.contractStartDate)} to ${toDateShort(foundQuoteFromAllQuote.contractEndDate)}
- TCV: ${formatCurrency(foundQuoteFromAllQuote.total, { currency: foundQuoteFromAllQuote.currency })} `
            : ''),
      };
    });
};

export const getContactsFromDRResp = (
  contacts?: DealRoomContactsResp | null,
): DealRoomNextStepContact => {
  return {
    billingId: contacts?.billingContact?.id || '',
    shippingId: contacts?.shippingContact?.id || '',
    useSameShippingBilling:
      !!contacts?.billingContact?.id &&
      !!contacts?.shippingContact?.id &&
      contacts.billingContact?.id === contacts.shippingContact?.id,
  };
};

export const getEsignContactsFromDRResp = (
  dealRoomResp?: DealRoomResp | null,
): DealRoomNextStepEsignContact => {
  return {
    internalEsignContactId:
      (dealRoomResp?.contacts?.esign || []).find(({ internal }) => internal)
        ?.id || '',
    externalEsignContactId:
      (dealRoomResp?.contacts?.esign || []).find(({ internal }) => !internal)
        ?.id || '',
    requiresEsign: dealRoomResp?.requiresEsign || false,
    signingOrder: dealRoomResp?.signingOrder || SigningOrderEnum.INSTANTANEOUS,
    esignCcContacts: dealRoomResp?.contacts?.additionalESignRecipients || [],
  };
};

export const getDRReqContacts = (
  contacts: DealRoomNextStepContact,
  esignContacts: DealRoomNextStepEsignContact,
) => {
  return {
    billingId: contacts.billingId,
    shippingId: contacts.shippingId,
    esignIds: [
      esignContacts.internalEsignContactId,
      esignContacts.externalEsignContactId,
    ].filter(Boolean) as string[],
    esignCcIds: esignContacts.esignCcContacts.map(({ id }) => id),
  };
};
