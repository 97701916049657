import { BoxProps } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useMemo } from 'react';
import { Controller, useForm, useFormContext } from 'react-hook-form';
import { z } from 'zod';
import {
  MBox,
  MDivider,
  MFlex,
  MFormField,
  MText,
  MTextarea,
} from '../../../../../../components/Monetize';
import {
  DealRoomQuoteResp,
  DealRoomReq,
} from '../../../../../../types/dealroomTypes';
import { DealRoomCard } from '../../../../../components/DealRoom/DealRoomCard';
import { useDealRoomContext } from '../../dealRoomContext';
import { useDRSectionEditMode } from '../../useDRSectionEditMode';

interface QuoteOptionNoteEditProps extends BoxProps {
  dealRoomQuote: DealRoomQuoteResp;
}
export const QuoteOptionNoteEdit: React.FC<QuoteOptionNoteEditProps> = ({
  dealRoomQuote,
  ...rest
}: QuoteOptionNoteEditProps) => {
  const { handleSubmitButton, isDealRoomReadOnly, isDRSaving } =
    useDealRoomContext();
  const formContext = useFormContext<DealRoomReq>();
  const quotesWatch = formContext.getValues('quotes');
  const contentWatch = useMemo(
    () =>
      quotesWatch?.find(({ quoteId }) => quoteId === dealRoomQuote.quote.id)
        ?.note || '',
    [quotesWatch, dealRoomQuote.quote.id],
  );

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<{ content: string }>({
    resolver: zodResolver(z.object({ content: z.string().nonempty() })),
    mode: 'onBlur',
    defaultValues: { content: contentWatch },
  });

  const handleEdit = () => {};

  const onSubmit = async (data) => {
    const newQuotes = quotesWatch.map((q) => {
      if (q.quoteId === dealRoomQuote.quote.id) {
        return {
          ...q,
          note: data.content,
        };
      }
      return q;
    });
    formContext.setValue('quotes', newQuotes, {
      shouldDirty: true,
      shouldValidate: true,
    });
    await handleSubmitButton();
    setIsEditing(false);
  };

  const { iconGroups, isEditing, setIsEditing } = useDRSectionEditMode({
    isReadOnly: isDealRoomReadOnly,
    isLoading: isDRSaving,
    handleEdit,
    handleCancel: () => {
      reset({ content: contentWatch });
      setIsEditing(false);
    },
    useIconButtons: true,
  });

  useEffect(() => {
    if (!isEditing) {
      reset({ content: contentWatch });
    }
  }, [contentWatch, isEditing]);

  return (
    <DealRoomCard {...rest}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <MFlex justifyContent="space-between" alignItems="center">
          <MText>Note</MText>
          <MBox textAlign="right">{iconGroups.allIcons}</MBox>
        </MFlex>
        <MDivider my="2" />

        {isEditing ? (
          <MFormField error={errors.content}>
            <Controller
              name="content"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <MTextarea placeholder="Notes" {...field} />
              )}
            />
          </MFormField>
        ) : (
          <MBox>
            {contentWatch && (
              <MText fontSize="md" whiteSpace="pre-wrap">
                {contentWatch}
              </MText>
            )}
            {!contentWatch && (
              <MText fontStyle="italic" fontSize="sm">
                Blank
              </MText>
            )}
          </MBox>
        )}
      </form>
    </DealRoomCard>
  );
};
