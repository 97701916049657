import { useEffect, useState } from 'react';
import {
  MBox,
  MButton,
  MCloseButton,
  MGrid,
  MGridItem,
  MText,
  MTooltip,
} from '../../../../../../components/Monetize';
import { IContactRespSchema } from '../../../../../../types';
import { IDealRoomContactRespSchema } from '../../../../../../types/dealroomTypes';
import { sortAlphabetically } from '../../../../../../utils';
import { NextStepsSectionContactInfoContactInput } from './NextStepsSectionContactInfoContactInput';
import { NextStpesSectionAdditionalEsignNew } from './NextStpesSectionAdditionalEsignNew';

interface INextStpesSectionAdditionalEsignProps {
  isDisabled: boolean;
  accountId: string;
  loadingContacts: boolean;
  contacts: IContactRespSchema[]; // external(Accounts) contacts
  selectedContacts: IDealRoomContactRespSchema[];
  selectedContactIds: string[];
  onSelectedContactsChange?: (contactId: string) => void;
  handleCreatedNewContact?: (contact: IContactRespSchema) => void;
  onRemoveAdditionalContact: (contactId: string) => void;
}

const AdditionalContactItems = ({
  contact,
  onRemove,
  isDisabled,
}: {
  contact: IDealRoomContactRespSchema;
  onRemove: (id: string) => void;
  isDisabled: boolean;
}) => {
  const { id, fullName, email } = contact;

  return (
    <>
      <MGridItem colSpan={6} mt="2">
        <MText isTruncated w="full">
          {fullName}
        </MText>
      </MGridItem>
      <MGridItem colSpan={4} mt="2">
        <MText isTruncated w="full">
          {email}
        </MText>
      </MGridItem>

      <MGridItem colSpan={2} textAlign="right" mt="1">
        {!isDisabled && (
          <MTooltip label="Remove Contact" placement="bottom-end">
            <MCloseButton
              aria-label="Remove Contact"
              onClick={() => onRemove(id)}
            />
          </MTooltip>
        )}
      </MGridItem>
    </>
  );
};

export const NextStpesSectionAdditionalEsign = ({
  isDisabled,
  accountId,
  loadingContacts,
  contacts,
  selectedContacts,
  selectedContactIds,
  onSelectedContactsChange,
  handleCreatedNewContact = (contact: IContactRespSchema) => {},
  onRemoveAdditionalContact,
}: INextStpesSectionAdditionalEsignProps) => {
  const [availableContacts, setAvailableContacts] = useState(contacts);
  const [addContactActive, setAddContactActive] = useState(
    !loadingContacts && !selectedContacts?.length,
  );
  const [newContactOpen, setNewContactOpen] = useState(false);

  useEffect(() => {
    if (contacts && selectedContacts) {
      const contactIds = new Set(selectedContacts.map(({ id }) => id));
      setAvailableContacts(
        contacts
          .filter(
            ({ id }) => !contactIds.has(id) && !selectedContactIds.includes(id),
          )
          .sort((a, b) => sortAlphabetically('fullName')(a, b)),
      );
    }
  }, [contacts, selectedContacts, selectedContactIds]);

  const handleOnNewContactSelect = () => {
    setAddContactActive(false);
    setNewContactOpen(true);
  };

  const onCloseContactForm = (contact?: IContactRespSchema) => {
    if (contact) {
      handleCreatedNewContact && handleCreatedNewContact(contact);
    }

    setNewContactOpen(false);
    setAddContactActive(true);
  };

  return (
    <MGrid templateColumns="repeat(12, 1fr)" columnGap={4} mt={2}>
      <MGridItem colSpan={12} mb="2">
        <MText fontSize="md" fontWeight="bold">
          eSign CC
        </MText>
        <MText mt="1">
          Add the information of any people you would like to CC after
          completing the eSign.
        </MText>
      </MGridItem>
      {selectedContacts.length > 0 && (
        <>
          <MGridItem colSpan={6}>
            <MText fontWeight="bold">Name</MText>
          </MGridItem>
          <MGridItem colSpan={6}>
            <MText fontWeight="bold">Email</MText>
          </MGridItem>
        </>
      )}
      {selectedContacts
        ?.sort(sortAlphabetically('fullName'))
        .map((contact) => (
          <AdditionalContactItems
            key={contact.id}
            contact={contact}
            onRemove={onRemoveAdditionalContact}
            isDisabled={isDisabled}
          />
        ))}

      {newContactOpen && !isDisabled && (
        <MGridItem colSpan={12} mt="2">
          <NextStpesSectionAdditionalEsignNew
            accountId={accountId}
            isCcContact
            isCompact={false}
            onClose={onCloseContactForm}
          />
        </MGridItem>
      )}
      {!addContactActive && !newContactOpen && !isDisabled && (
        <MGridItem colSpan={12} mt="2">
          <MBox>
            <MButton
              variant="tertiary"
              size="sm"
              onClick={() => setAddContactActive(true)}
            >
              + Add Contact
            </MButton>
          </MBox>
        </MGridItem>
      )}

      {addContactActive && !isDisabled && (
        <MGridItem colSpan={6} mt="2">
          <NextStepsSectionContactInfoContactInput
            contacts={availableContacts}
            onChange={(value) =>
              onSelectedContactsChange && onSelectedContactsChange(value)
            }
            onNewContact={handleOnNewContactSelect}
            canCreateContact={true}
            isDisabled={isDisabled}
            placeholder="Select Contact"
          />
        </MGridItem>
      )}
    </MGrid>
  );
};
