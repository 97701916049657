import { BoxProps } from '@chakra-ui/react';
import { truncateFileName } from '@monetize/utils/core';
import React, { useEffect, useState } from 'react';
import { MBox, MFlex, MLink, MText } from '..';
import { IStorage } from '../../../types';
import { base64ToArrayBuffer } from '../../../utils';
import { downloadBlobAsFile } from '../../../utils/download';
import { PreviewPdf } from './PreviewPdf';

interface PreviewStorageFileProps extends BoxProps {
  file: IStorage;
}

export const PreviewStorageFile: React.FC<PreviewStorageFileProps> = ({
  file,
  ...rest
}) => {
  const [document, setDocument] = useState<ArrayBuffer | null>(null);
  useEffect(() => {
    setDocument(base64ToArrayBuffer(file.contentBase64Encoded!));
  }, [file]);

  const downloadFile = async () => {
    downloadBlobAsFile(document, file.filename);
  };

  if (file.mediaType === 'application/pdf') {
    return (
      <MFlex flex={1} {...rest}>
        <PreviewPdf
          currentPdfFile={document}
          filename={file.filename}
          showDownloadButton={false}
        />
      </MFlex>
    );
  }

  return (
    <MBox {...rest}>
      <MLink
        textDecor="underline"
        fontSize="sm"
        onClick={downloadFile}
        maxW="100px"
      >
        {truncateFileName(file.filename, 50)}
      </MLink>{' '}
      <MText fontStyle="italic">Preview not available for this file type</MText>
    </MBox>
  );
};
