import { BoxProps } from '@chakra-ui/react';
import { MBox } from '../../../../../../components/Monetize';
import { DealRoomDocumentSectionKeyEnum } from '../../../../../../types/dealroomTypes';
import { DealRoomContentListEditor } from '../../DealRoomContentList/DealRoomContentListEditor';

interface ExtraInfoSectionProps extends BoxProps {}
export const ExtraInfoSection: React.FC<ExtraInfoSectionProps> = ({
  ...rest
}) => {
  return (
    <MBox w="full" {...rest}>
      <DealRoomContentListEditor
        sectionKey={DealRoomDocumentSectionKeyEnum.EXTRA_INFO}
      />
    </MBox>
  );
};
