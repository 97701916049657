import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { MGrid, MGridItem } from '../../../../../../components/Monetize';
import { useDealRoomContext } from '../../dealRoomContext';
import { NextStepsSectionSelectQuoteItem } from './NextStepsSectionSelectQuoteItem';
import { NextStepsSectionStep } from './NextStepsSectionStep';

interface NextStepsSectionSelectQuoteProps {}
export const NextStepsSectionSelectQuote: React.FC<
  NextStepsSectionSelectQuoteProps
> = ({}) => {
  const {
    dealRoomResp: { quotes = [], selectedQuoteId },
    handleSubmitButton,
  } = useDealRoomContext();
  const { control } = useFormContext();
  const [internalSelectedQuoteId, setInternalSelectedQuoteId] =
    useState(selectedQuoteId);

  const isEditable = true;

  return (
    <NextStepsSectionStep
      stepNumber={3}
      title="Select a quote to move forward with:"
    >
      <MGrid templateColumns="repeat(12, 1fr)" gap={4}>
        <Controller
          name={'selectedQuoteId'}
          control={control}
          render={({ field: { value, onChange, ...field } }) => (
            <>
              {quotes.map((quote, index) => (
                <MGridItem
                  colSpan={[12, 12, 4]}
                  key={`quote-${quote.quote.id}`}
                  justifyContent="center"
                  display="flex"
                >
                  <NextStepsSectionSelectQuoteItem
                    dealRoomQuote={quote}
                    isSelected={quote.quote.id === internalSelectedQuoteId}
                    isEditable={isEditable}
                    onChange={(quoteId: string) => {
                      onChange(quoteId);
                      setInternalSelectedQuoteId(quoteId);
                      handleSubmitButton();
                    }}
                  />
                </MGridItem>
              ))}
            </>
          )}
        />
      </MGrid>
    </NextStepsSectionStep>
  );
};
