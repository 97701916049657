import { BoxProps } from '@chakra-ui/react';
import { MBox, MText } from '../../../../../../components/Monetize';
import { NextStepsSectionContactInfo } from './NextStepsSectionContactInfo';
import { NextStepsSectionPONumber } from './NextStepsSectionPONumber';
import { NextStepsSectionSelectQuote } from './NextStepsSectionSelectQuote';
import { NextStepsSectionSign } from './NextStepsSectionSign';

interface NextStepsSectionProps extends BoxProps {}
export const NextStepsSection: React.FC<NextStepsSectionProps> = ({
  ...rest
}) => {
  return (
    <MBox w="full" maxW="900px" alignSelf="flex-start" {...rest}>
      <MText fontSize="3xl" color="tPurple.500" fontWeight="bold" mb="4">
        Next Steps
      </MText>

      <NextStepsSectionContactInfo />
      <NextStepsSectionPONumber />
      <NextStepsSectionSelectQuote />
      <NextStepsSectionSign />
    </MBox>
  );
};
