import { useEffect, useMemo, useState } from 'react';
import { FaTrash } from 'react-icons/fa';
import { MdCheck, MdClose, MdEdit } from 'react-icons/md';
import { handleApiErrorToast } from '../../../../api/axios';
import { MButton, MCustomIconButton } from '../../../../components/Monetize';
import { useConfirmModal } from '../../../../services/confirmModal';

export const useDRSectionEditMode = ({
  isReadOnly,
  isLoading,
  handleEdit,
  handleCancel,
  useIconButtons,
  handleDelete,
  showDeletedButton,
  isSaveValid = true,
}: {
  isReadOnly: boolean;
  isLoading: boolean;
  handleEdit?: () => void;
  handleCancel?: () => void;
  useIconButtons?: boolean;
  handleDelete?: () => Promise<void>;
  showDeletedButton?: boolean;
  isSaveValid?: boolean;
}) => {
  const { showConfirmModal, setModalLoading, closeModal } = useConfirmModal();

  const [isEditing, setIsEditing] = useState(false);
  const handleToggleEdit = () => {
    setIsEditing(!isEditing);
  };
  useEffect(() => {
    if (isReadOnly) {
      setIsEditing(false);
    }
  }, [isReadOnly]);

  const internalHandleEdit = async () => {
    handleEdit && handleEdit();
    setIsEditing(true);
  };
  const internalHandleDelete = async () => {
    const onYes = async () => {
      try {
        setModalLoading(true);
        handleDelete && (await handleDelete());
      } catch (error) {
        handleApiErrorToast(error);
      }
      setModalLoading(false);
      closeModal();
    };

    showConfirmModal({
      title: `Are you sure you want to delete?`,
      onYes,
      yesBtnProps: {
        variant: 'delete',
      },
      noBtnProps: {
        variant: 'cancel',
      },
      yesButton: 'Delete',
      noButton: 'Cancel',
    });
  };

  const iconGroups = useMemo(() => {
    if (isReadOnly) {
      return {
        allIcons: null,
        saveIcon: null,
        editIcon: null,
      };
    }
    const editIcon = (
      <>
        <MCustomIconButton
          btnSize={5}
          width={5}
          height={5}
          variant="icon"
          icon={MdEdit}
          iconColor="tBlue.400"
          onClick={internalHandleEdit}
        />

        {showDeletedButton && (
          <MCustomIconButton
            btnSize={4}
            width={5}
            height={5}
            variant="icon"
            icon={FaTrash}
            iconColor="tRed.base"
            onClick={internalHandleDelete}
          />
        )}
      </>
    );

    const saveIcon = (
      <>
        {!useIconButtons && (
          <>
            <MButton
              variant="cancel"
              minW="auto"
              mr="2"
              isDisabled={isLoading}
              onClick={handleCancel}
            >
              Cancel
            </MButton>
            <MButton
              type="submit"
              isLoading={isLoading}
              minW="auto"
              isDisabled={!isSaveValid}
            >
              Save
            </MButton>
          </>
        )}
        {useIconButtons && (
          <>
            <MCustomIconButton
              btnSize={5}
              width={6}
              height={6}
              icon={MdClose}
              isDisabled={isLoading}
              variant="icon"
              onClick={handleCancel}
              mr="2"
            />

            <MCustomIconButton
              btnSize={5}
              width={6}
              height={6}
              icon={MdCheck}
              isLoading={isLoading}
              iconColor="tBlue.400"
              variant="icon"
              type="submit"
              isDisabled={!isSaveValid}
            />
          </>
        )}
      </>
    );
    return {
      allIcons: isEditing ? saveIcon : editIcon,
      saveIcon,
      editIcon,
    };
  }, [
    isReadOnly,
    isEditing,
    internalHandleEdit,
    useIconButtons,
    isLoading,
    handleCancel,
  ]);

  return {
    handleToggleEdit,
    isEditing,
    setIsEditing,
    iconGroups,
  };
};
