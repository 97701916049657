import { BoxProps, Tbody, Td, Thead, Tr } from '@chakra-ui/react';
import update from 'immutability-helper';
import { FormEvent, useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  MBox,
  MFlex,
  MSkeleton,
  MTable,
  MText,
} from '../../../../../../components/Monetize';
import { logger } from '../../../../../../services/logger';
import {
  DealRoomQuoteSelectRow,
  DealRoomReq,
} from '../../../../../../types/dealroomTypes';
import {
  getFormQuotesFromDRResp,
  setDRReqFromFormQuote,
} from '../../../../../utils/dealRoomUtils';
import { useDealRoomContext } from '../../dealRoomContext';
import { useDRSectionEditMode } from '../../useDRSectionEditMode';
import { QuoteOptionSectionSelectTableRow } from './QuoteOptionSectionSelectTableRow';

const MAX_ALLOWED_QUOTES_COUNT = 5;

interface QuoteOptionSectionSelectTableProps extends BoxProps {}
export const QuoteOptionSectionSelectTable: React.FC<
  QuoteOptionSectionSelectTableProps
> = ({ ...rest }) => {
  const {
    dealRoomResp: { opportunity, quotes },
    isDealRoomReadOnly,
    isDRSaving,
    handleSubmitButton,
    opportunityWithQuotes: {
      data: opportunityWithQuotes,
      isFetched: isOpportunityFetched,
    },
  } = useDealRoomContext();
  const formContext = useFormContext<DealRoomReq>();
  const quotesWatch = formContext.getValues('quotes');

  const { iconGroups, isEditing, setIsEditing } = useDRSectionEditMode({
    isReadOnly: isDealRoomReadOnly,
    isLoading: isDRSaving,
    handleCancel: () => {
      setFormQuotes(
        getFormQuotesFromDRResp(
          opportunityWithQuotes?.quotes || [],
          quotesWatch || [],
        ),
      );
      setIsEditing(false);
    },
  });

  const [formQuotes, setFormQuotes] = useState<DealRoomQuoteSelectRow[]>([]);

  useEffect(() => {
    if (isOpportunityFetched || (!isEditing && quotesWatch)) {
      setFormQuotes(
        getFormQuotesFromDRResp(
          opportunityWithQuotes?.quotes || [],
          quotesWatch || [],
        ),
      );
    }
  }, [opportunityWithQuotes, isOpportunityFetched, quotesWatch, isEditing]);

  const handleSubmit = async (ev: FormEvent<HTMLFormElement>) => {
    ev.preventDefault();

    const newQuotes = setDRReqFromFormQuote(
      quotesWatch,
      formQuotes,
      opportunityWithQuotes?.quotes || [],
    );
    formContext.setValue('quotes', newQuotes, {
      shouldDirty: true,
      shouldValidate: true,
    });
    await handleSubmitButton();
    setIsEditing(false);
  };

  const saveMoveChanges = useCallback(() => {
    // we dont save right away, but we will save when "Save" is clicked
  }, []);

  const moveRow = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      try {
        setFormQuotes((prevQuote) =>
          update(prevQuote, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, prevQuote[dragIndex]],
            ],
          }),
        );
      } catch (ex) {
        logger.warn('Error reordering', ex);
      }
    },
    [formQuotes],
  );

  const onSelectUpdated = useCallback(
    (quoteId: string, selected: boolean) => {
      try {
        setFormQuotes((prevQuote) =>
          prevQuote
            .map((quote) => {
              if (quote.quoteId === quoteId) {
                return { ...quote, selected };
              }
              return quote;
            })
            .sort((a, b) => (b.selected ? 1 : 0) - (a.selected ? 1 : 0)),
        );
      } catch (ex) {
        logger.warn('Error updating selectiong', ex);
      }
    },
    [formQuotes],
  );

  return (
    <MBox w="full" {...rest}>
      <form onSubmit={handleSubmit}>
        <MFlex justify="space-between" alignItems="flex-start">
          <MBox>
            <MText fontSize="md" color="tPurple.500" fontWeight="bold" my="1">
              Quotes in in this Opportunity
            </MText>
            <MText fontSize="sm" color="tPurple.500" mb="4">
              The order quotes are shown is dictated by their place in this
              list.
            </MText>
          </MBox>

          <MBox textAlign="right">{iconGroups.allIcons}</MBox>
        </MFlex>

        <MTable mt={5} overflow="scroll" variant="border">
          <Thead>
            <Tr>
              <Td textAlign="center" width="170px">
                <MText>Show in Dealroom</MText>
                <MText color="tGray.darkPurple">
                  ({MAX_ALLOWED_QUOTES_COUNT} Maximum)
                </MText>
              </Td>
              <Td textAlign="left">Name</Td>
              <Td textAlign="left">Contract</Td>
              <Td textAlign="left">Period</Td>
              <Td textAlign="right">TCV</Td>
            </Tr>
          </Thead>
          <Tbody>
            {!isOpportunityFetched && (
              <Tr>
                <Td colSpan={100}>
                  <MSkeleton height="20px" />
                </Td>
              </Tr>
            )}

            {formQuotes.map((rowData, index) => (
              <QuoteOptionSectionSelectTableRow
                index={index}
                key={index}
                rowData={rowData}
                moveRow={moveRow}
                saveMoveChanges={saveMoveChanges}
                allQuotes={opportunityWithQuotes?.quotes || []}
                formQuotes={formQuotes}
                onSelectUpdated={onSelectUpdated}
                isEditing={isEditing}
                allowedQuotesCount={MAX_ALLOWED_QUOTES_COUNT}
              />
            ))}
          </Tbody>
        </MTable>
      </form>
    </MBox>
  );
};
