import { forwardRef, Ref, SVGProps } from 'react';

export const MonetizeNowIcon = forwardRef<
  SVGSVGElement,
  SVGProps<SVGSVGElement>
>((props, ref: Ref<SVGSVGElement>) => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.49504 3.12485L7.47961 2.95039C7.36646 1.2959 5.9192 0 4.18484 0C2.4503 0 1.00323 1.2959 0.890076 2.95039L0.832031 3.79947V16L7.49504 14.5784V3.12485Z"
        fill="#4659DA"
      />
      <path
        d="M8.43661 3.157V14.431L12.1737 13.6338V3.17819L12.1583 3.0041C12.0453 1.34961 10.5979 0.0537109 8.86368 0.0537109C8.30289 0.0537109 7.77332 0.1909 7.30786 0.430023C7.94121 1.10172 8.35138 1.97107 8.41842 2.93341L8.43661 3.157Z"
        fill="#4659DA"
      />
      <path
        d="M13.0948 3.15114V13.4263L16.8121 12.6333L16.8321 12.6353V3.17233L16.8165 2.99824C16.7035 1.34375 15.2562 0.0478516 13.5217 0.0478516C12.9611 0.0478516 12.4315 0.185041 11.9661 0.424346C12.5994 1.09586 13.0096 1.96521 13.0768 2.92755L13.0948 3.15114Z"
        fill="#4659DA"
      />
    </svg>
  );
});
