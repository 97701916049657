import { BoxProps } from '@chakra-ui/react';
import { MBox } from '../../../../../../components/Monetize';
import { DealRoomDocumentSectionKeyEnum } from '../../../../../../types/dealroomTypes';
import { DealRoomContentListEditor } from '../../DealRoomContentList/DealRoomContentListEditor';

interface FeatureCaseSectionProps extends BoxProps {}
export const FeatureCaseSection: React.FC<FeatureCaseSectionProps> = ({
  ...rest
}) => {
  return (
    <MBox w="full" {...rest}>
      <DealRoomContentListEditor
        sectionKey={DealRoomDocumentSectionKeyEnum.FEATURES}
        allowEditTitle={true}
      />
    </MBox>
  );
};
