import { useEffect, useState } from 'react';
import { handleApiErrorToast } from '../../../../api/axios';
import { DealRoomReq, DealRoomResp } from '../../../../types/dealroomTypes';
import {
  doGetDealRoomById,
  useUpdateDealRoom,
} from '../../../api/dealRoomService';

export const useDealRoom = (dealRoomId: string) => {
  const [dealRoomResp, setDealRoomResp] = useState<DealRoomResp | null>(null);
  const [isInitialLoading, setIsInitialLoading] = useState<boolean>(true);
  const [isDRSaving, setisDRSaving] = useState<boolean>(false);
  const [isDRSavingErrored, setisDRSavingErrored] = useState<boolean>(false);
  const { mutateAsync: doUpdateDealRoom } = useUpdateDealRoom();

  useEffect(() => {
    (async () => {
      setIsInitialLoading(true);
      try {
        const data = await doGetDealRoomById(dealRoomId);
        setDealRoomResp(data);
      } catch (err) {
        handleApiErrorToast(err);
      }
      setIsInitialLoading(false);
    })();
  }, [dealRoomId]);

  const handleUpdateDealRoom = async (data: DealRoomReq) => {
    try {
      setisDRSavingErrored(false);

      setisDRSaving(true);
      const updateDealRoom = await doUpdateDealRoom({
        dealRoomId,
        payload: data,
      });
      setDealRoomResp(updateDealRoom);
    } catch (err) {
      setisDRSavingErrored(true);
      handleApiErrorToast(err);
    } finally {
      setisDRSaving(false);
    }
  };

  return {
    dealRoomResp,
    isInitialLoading,
    handleUpdateDealRoom,
    isDRSaving,
    isDRSavingErrored,
    setDealRoomResp,
  };
};
