import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { MBox, MStack } from '../../../../../../components/Monetize';
import {
  DealRoomNextStepContact,
  DealRoomNextStepContactSchema,
} from '../../../../../../types/dealroomTypes';
import { useDealRoomContext } from '../../dealRoomContext';
import { useDRSectionEditMode } from '../../useDRSectionEditMode';
import { NextStepsSectionContactInfoContact } from './NextStepsSectionContactInfoContact';
import { NextStepsSectionStep } from './NextStepsSectionStep';

interface NextStepsSectionContactInfoProps {}
export const NextStepsSectionContactInfo: React.FC<
  NextStepsSectionContactInfoProps
> = () => {
  const { isDealRoomReadOnly, dealRoomContacts, dealRoomResp } =
    useDealRoomContext();
  const { handleSaveContacts, isContactsSaving, contacts } = dealRoomContacts;

  const contactForm = useForm<DealRoomNextStepContact>({
    resolver: zodResolver(DealRoomNextStepContactSchema),
    mode: 'onBlur',
    defaultValues: contacts,
  });
  const {
    handleSubmit,
    reset,
    watch,
    formState: { isValid },
  } = contactForm;
  const useSameShippingBillingWatch = watch('useSameShippingBilling');

  const onSubmit = async (data: DealRoomNextStepContact) => {
    await handleSaveContacts({
      contactForm: data,
    });
    setIsEditing(false);
  };

  const { iconGroups, isEditing, setIsEditing } = useDRSectionEditMode({
    isReadOnly: isDealRoomReadOnly,
    isLoading: isContactsSaving,
    handleCancel: () => {
      reset(contacts);
      setIsEditing(false);
    },
    isSaveValid: isValid,
  });

  useEffect(() => {
    if (!isEditing) {
      reset(contacts);
    }
  }, [contacts, isEditing]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <NextStepsSectionStep
        stepNumber={1}
        title="Confirm Contact information:"
        labelRightContent={iconGroups.allIcons}
      >
        <MBox position="relative">
          <MStack spacing="6" direction={['column', 'row']} w="100%">
            <NextStepsSectionContactInfoContact
              label="Billing Contact"
              fieldName="billingId"
              dealRoomContacts={dealRoomContacts}
              flex="1"
              contactForm={contactForm}
              isDisabled={!isEditing}
              accountId={dealRoomResp.accountId}
            />

            {!useSameShippingBillingWatch ? (
              <NextStepsSectionContactInfoContact
                label="Shipping Contact"
                fieldName="shippingId"
                dealRoomContacts={dealRoomContacts}
                flex="1"
                contactForm={contactForm}
                isDisabled={!isEditing}
                accountId={dealRoomResp.accountId}
              />
            ) : (
              <MBox flex="1" />
            )}
          </MStack>
        </MBox>
      </NextStepsSectionStep>
    </form>
  );
};
