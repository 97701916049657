import { BoxProps } from '@chakra-ui/react';
import { MBox } from '../../../components/Monetize';

interface DealRoomCardProps extends BoxProps {
  children?: React.ReactNode;
}
export const DealRoomCard: React.FC<DealRoomCardProps> = ({
  children,
  ...rest
}) => {
  return (
    <MBox
      background="white"
      borderRadius="8px"
      p="2"
      boxShadow="0px 1px 16px 0px #C8C5D426"
      {...rest}
    >
      {children}
    </MBox>
  );
};
