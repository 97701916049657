import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { MBox, MFlex } from '../../../../../components/Monetize';
import {
  DealRoomDocumentSectionKeyEnum,
  DealRoomReq,
} from '../../../../../types/dealroomTypes';
import { DEAL_ROOM_SECTION_IDS } from '../../../../constants/dealroom';
import { getSectionFromSections } from '../../../../utils/dealRoomUtils';
import { useDealRoomContext } from '../dealRoomContext';
import { DealRoomSideBarMenuItem } from './DealRoomSideBarMenuItem';

interface ProgressStepperProps {}

export const DealRoomSideBar: React.FC<ProgressStepperProps> = ({}) => {
  const currentHash = window.location.hash && window.location.hash.slice(1);
  const [activeSection, setActiveSection] = useState(currentHash);
  const {
    dealRoomResp: { opportunity },
  } = useDealRoomContext();
  const formContext = useFormContext<DealRoomReq>();
  const sectionsWatch = formContext.getValues('document.document.sections');

  const STEP_LIST = [
    { label: 'Welcome!', id: DEAL_ROOM_SECTION_IDS.DR_WELCOME },
    {
      label:
        getSectionFromSections(
          sectionsWatch,
          DealRoomDocumentSectionKeyEnum.FEATURES,
        )?.title || 'Features & Case Studies',
      id: DEAL_ROOM_SECTION_IDS.DR_FEATURE_CASE,
    },
    { label: 'Quote Options', id: DEAL_ROOM_SECTION_IDS.DR_QUOTE_OPTION },
    {
      label:
        getSectionFromSections(
          sectionsWatch,
          DealRoomDocumentSectionKeyEnum.EXTRA_INFO,
        )?.title || 'Extra Information',
      id: DEAL_ROOM_SECTION_IDS.DR_EXTRA_INFO,
    },
    { label: 'Next Steps', id: DEAL_ROOM_SECTION_IDS.DR_NEXT_STEPS },
  ];

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll('.dr-section');
      let activeSection = '';

      const scrollTop = window.scrollY;
      const scrollHeight =
        document.documentElement.scrollHeight - window.innerHeight;

      if (scrollTop >= scrollHeight - 10) {
        activeSection = sections[sections.length - 1].id;
      } else {
        sections.forEach((section, index) => {
          const rect = section.getBoundingClientRect();
          if (rect.top >= 0 && rect.top < 200) {
            activeSection = section.id;
          }
        });
      }

      if (activeSection) {
        setActiveSection(activeSection);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [opportunity]);

  const activeSectionIndex =
    STEP_LIST.findIndex(({ id }) => id === activeSection) || 0;

  const scrollToSection = (id) => {
    const stickyBarHeight = 60; // Adjust based on your sticky bar's height
    const targetElement = document.getElementById(id);

    if (targetElement) {
      const offsetTop = targetElement.offsetTop - stickyBarHeight;
      window.scrollTo({ top: offsetTop, behavior: 'smooth' });
    }
  };

  return (
    <MFlex width="100%" height="100vh" bg="tPurple.600" p="6">
      <MFlex direction="column" w="full" flexDir="column">
        <MBox flex="1">
          {STEP_LIST.map((step, index) => (
            <DealRoomSideBarMenuItem
              key={step.label}
              label={step.label}
              showBullet={true}
              iconColor={
                index < activeSectionIndex ? 'tIndigo.base' : 'tWhite.base'
              }
              bulletColor={
                index <= activeSectionIndex ? 'tIndigo.base' : 'tWhite.base'
              }
              showLine={index < STEP_LIST.length - 1}
              onClick={() => {
                scrollToSection(step.id);
              }}
            />
          ))}

          {/* TODO: Add analytics */}
          {/* <DealRoomSideBarMenuItem
            label="Analytics"
            icon={AnalyticsIcon}
            iconColor="tBlue.100"
          /> */}
          {/* <DealRoomSideBarMenuItem
            label="MonetizeNow"
            icon={MonetizeNowIcon}
            iconColor="tBlue.100"
          /> */}
        </MBox>
        {/* <DealRoomSideBarMenuItem
          label="Support"
          icon={MdOutlineHelpOutline}
          iconColor="tBlue.100"
        /> */}
      </MFlex>
    </MFlex>
  );
};
