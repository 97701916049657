import { MBox, MFlex, MLink } from '../../../../../../components/Monetize';
import { ROUTES } from '../../../../../../constants';
import { DealRoomQuoteResp } from '../../../../../../types/dealroomTypes';
import { QuoteOptionNameEdit } from './QuoteOptionNameEdit';
import { QuoteOptionNoteEdit } from './QuoteOptionNoteEdit';
import { QuoteOptionSelectHtml } from './QuoteOptionSelectHtml';

interface QuoteOptionSectionTabProps {
  dealRoomQuote: DealRoomQuoteResp;
}
export const QuoteOptionSectionTab: React.FC<QuoteOptionSectionTabProps> = ({
  dealRoomQuote,
}: QuoteOptionSectionTabProps) => {
  return (
    <MBox>
      <MBox textAlign="right">
        <MLink
          mr={2}
          variant="tertiary"
          href={ROUTES.getQuoteBaseRoute(dealRoomQuote.quote.id)}
          target="_blank"
          my="1"
        >
          View Quote
        </MLink>
      </MBox>
      <MFlex>
        <MBox width="200px">
          <QuoteOptionNameEdit dealRoomQuote={dealRoomQuote} />
          <QuoteOptionNoteEdit dealRoomQuote={dealRoomQuote} mt="4" />
        </MBox>

        <MBox flex="1" bg="tWhite.titanWhite" ml="4" minH="600px">
          <QuoteOptionSelectHtml quoteId={dealRoomQuote.quote.id} />
        </MBox>
      </MFlex>
    </MBox>
  );
};
