import { z } from 'zod';

export enum QuoteItemAmendmentStatusEnum {
  ADDED = 'ADDED',
  NO_CHANGE = 'NO_CHANGE',
  REMOVED = 'REMOVED',
  UPDATED = 'UPDATED',
}

export enum ContactStatusEnum {
  ACTIVE = 'ACTIVE',
  CANCELED = 'CANCELED',
}

export enum QuoteStatusEnum {
  DRAFT = 'DRAFT',
  REVIEW = 'REVIEW',
  APPROVED = 'APPROVED',
  DENIED = 'DENIED',
  SENT = 'SENT',
  ACCEPTED = 'ACCEPTED',
  ACCEPTED_ADMIN_EDITABLE = 'ACCEPTED_ADMIN_EDITABLE',
  PROCESSED = 'PROCESSED',
  CANCELED = 'CANCELED',
  EXPIRED = 'EXPIRED',
  ARCHIVED = 'ARCHIVED',
}

// Quote statuses that have an API endpoint to transition to them
export type QuoteStatusTransitions =
  | QuoteStatusEnum.DRAFT
  | QuoteStatusEnum.REVIEW
  | QuoteStatusEnum.SENT
  | QuoteStatusEnum.ACCEPTED;

export enum QuoteTypeEnum {
  NEW = 'NEW',
  AMENDMENT = 'AMENDMENT',
  RENEWAL = 'RENEWAL',
}
export enum QuoteCancelationReasonEnum {
  EXPIRED = 'EXPIRED',
  CUSTOMER_DECLINED = 'CUSTOMER_DECLINED',
  DUPLICATE = 'DUPLICATE',
  BY_USER = 'BY_USER',
}

export enum NewQuoteTypeEnum {
  NET_NEW = 'NET_NEW',
  MANUAL_RENEWAL = 'MANUAL_RENEWAL',
}

export enum CollaborationAccessEnum {
  VIEW = 'VIEW',
  EDIT = 'EDIT',
  NONE = 'NONE',
}

export enum CollaborationAccessLevelEnum {
  WRITE = 'WRITE',
  READ = 'READ',
}

export enum SigningOrderEnum {
  INTERNAL_FIRST = 'INTERNAL_FIRST',
  EXTERNAL_FIRST = 'EXTERNAL_FIRST',
  EXTERNAL_ONLY = 'EXTERNAL_ONLY',
  INSTANTANEOUS = 'INSTANTANEOUS',
}

export enum OpportunityStatusEnum {
  WON = 'WON',
  CANCELED = 'CANCELED',
  ACTIVE = 'ACTIVE',
  LOST = 'LOST',
}

export enum NetTermsEnum {
  NET_0 = 'NET_0',
  NET_5 = 'NET_5',
  NET_7 = 'NET_7',
  NET_8 = 'NET_8',
  NET_10 = 'NET_10',
  NET_14 = 'NET_14',
  NET_15 = 'NET_15',
  NET_20 = 'NET_20',
  NET_21 = 'NET_21',
  NET_30 = 'NET_30',
  NET_45 = 'NET_45',
  NET_60 = 'NET_60',
  NET_75 = 'NET_75',
  NET_90 = 'NET_90',
  NET_100 = 'NET_100',
  NET_120 = 'NET_120',
  NET_150 = 'NET_150',
  NET_180 = 'NET_180',
}

export enum ApprovalStatusEnum {
  NO_ACTION = 'NO_ACTION',
  DECLINED = 'DECLINED',
  APPROVED = 'APPROVED',
}

export enum AmountUnitTypeEnum {
  FLAT = 'FLAT',
  PERCENTAGE = 'PERCENTAGE',
}

export enum DiscountStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  PENDING = 'PENDING',
  EXPIRED = 'EXPIRED',
}

export enum DiscountDurationEnum {
  LIMITED = 'LIMITED',
  UNLIMITED = 'UNLIMITED',
  ONE_TIME = 'ONE_TIME',
}

export enum ProductTypeEnum {
  // ARREARS exists on the BE, but is not used in the FE: you cannot create an ARREARS product (but you can make a rate's subscription timing = ARREARS)
  ADVANCE = 'ADVANCE',
  // SUBSCRIPTION = 'SUBSCRIPTION',   // will eventually replace ADVANCE
  USAGE = 'USAGE',
  ONETIME = 'ONETIME',
  MIN_COMMIT = 'MIN_COMMIT',
  ONETIME_PREPAID_CREDIT = 'ONETIME_PREPAID_CREDIT',
}

export enum OfferingStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  CANCELED = 'CANCELED',
  PENDING = 'PENDING',
  EXPIRED = 'EXPIRED',
  ARCHIVED = 'ARCHIVED',
}

export enum OfferingTypesEnum {
  SUBSCRIPTION = 'SUBSCRIPTION',
  ONETIME = 'ONETIME',
  MIN_COMMIT = 'MIN_COMMIT',
  CUSTOM_PERCENT_OF_TOTAL = 'CUSTOM_PERCENT_OF_TOTAL',
}

export enum PriceModelEnum {
  VOLUME = 'VOLUME',
  TIERED = 'TIERED',
  FLAT = 'FLAT',
  CUSTOM = 'CUSTOM',
  PERCENT_OF_TOTAL = 'PERCENT_OF_TOTAL',
}

export enum ContractEndActionEnum {
  RENEW = 'RENEW',
  MANUAL_RENEW = 'MANUAL_RENEW',
  CANCEL = 'CANCEL',
}

export enum RenewalTermLengthEnum {
  ContractLength = 'ContractLength',
  FixedMonths = 'FixedMonths',
}

export enum QuoteSettingsDefaultAddressSourceEnum {
  CONTACT = 'CONTACT',
  ACCOUNT = 'ACCOUNT',
}

export enum StorageTypeEnum {
  MSA = 'MSA',
  SOW = 'SOW',
  COVER = 'COVER',
  FOOTER = 'FOOTER',
}
export const StorageTypeEnumZ = z.nativeEnum(StorageTypeEnum);

export enum StorageReferenceTypeEnum {
  QUOTE = 'QUOTE',
  QUOTE_CONFIG = 'QUOTE_CONFIG',
}

export enum RateBillingFrequencyEnum {
  DAILY = 'DAILY',
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
  SEMIANNUALLY = 'SEMIANNUALLY',
  ANNUALLY = 'ANNUALLY',
  CUSTOM = 'CUSTOM',
  ONETIME = 'ONETIME',
}

export const ContactStatusEnumZ = z.nativeEnum(ContactStatusEnum);
export const QuoteItemAmendmentStatusEnumZ = z.nativeEnum(
  QuoteItemAmendmentStatusEnum,
);
export const QuoteStatusEnumZ = z.nativeEnum(QuoteStatusEnum);
export const QuoteTypeEnumZ = z.nativeEnum(QuoteTypeEnum);
export const QuoteCancelationReasonEnumZ = z.nativeEnum(
  QuoteCancelationReasonEnum,
);
export const NewQuoteTypeEnumZ = z.nativeEnum(NewQuoteTypeEnum);
export const CollaborationAccessEnumZ = z.nativeEnum(CollaborationAccessEnum);
export const CollaborationAccessLevelEnumZ = z.nativeEnum(
  CollaborationAccessLevelEnum,
);
export const SigningOrderEnumZ = z.nativeEnum(SigningOrderEnum);
export const OpportunityStatusEnumZ = z.nativeEnum(OpportunityStatusEnum);
export const NetTermsEnumZ = z.nativeEnum(NetTermsEnum);
export const ApprovalStatusEnumZ = z.nativeEnum(ApprovalStatusEnum);
export const DiscountTypeEnumZ = z.nativeEnum(AmountUnitTypeEnum);
export const DiscountStatusEnumZ = z.nativeEnum(DiscountStatusEnum);
export const DiscountDurationEnumZ = z.nativeEnum(DiscountDurationEnum);
export const ProductTypeEnumZ = z.nativeEnum(ProductTypeEnum);
export const OfferingStatusEnumZ = z.nativeEnum(OfferingStatusEnum);
export const ContractEndActionEnumZ = z.nativeEnum(ContractEndActionEnum);
export const RenewalTermLengthEnumZ = z.nativeEnum(RenewalTermLengthEnum);
export const QuoteSettingsDefaultAddressSourceEnumZ = z.nativeEnum(
  QuoteSettingsDefaultAddressSourceEnum,
);
export const StorageReferenceTypeEnumZ = z.nativeEnum(StorageReferenceTypeEnum);
export const RateBillingFrequencyEnumZ = z.nativeEnum(RateBillingFrequencyEnum);

export enum ToggleState {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
}
