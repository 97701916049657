import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { apiGet, apiPut, apiUpload } from '../../api/axios';
import { IStorage } from '../../types';
import {
  DealRoomContactsReq,
  DealRoomContactsResp,
  DealRoomReq,
  DealRoomResp,
} from '../../types/dealroomTypes';

export const dealRoomServiceKeys = {
  base: ['deal-room'] as const,
  dealRoomById: (id: string) => [...dealRoomServiceKeys.base, id] as const,
  attachmentById: (dealRoomId: string, storageId) =>
    [
      ...dealRoomServiceKeys.dealRoomById(dealRoomId),
      'attachements',
      storageId,
    ] as const,
};

export function useDealRoomById(
  dealRoomId: string,
  options: Partial<UseQueryOptions<DealRoomResp>> = {},
) {
  return useQuery({
    queryKey: [...dealRoomServiceKeys.dealRoomById(dealRoomId)],
    queryFn: () =>
      apiGet<DealRoomResp>(`api/dealRooms/${dealRoomId}`).then(
        (res) => res.data,
      ),
    enabled: !!dealRoomId,
    refetchOnWindowFocus: false,
    ...options,
  });
}

export async function doGetDealRoomById(dealRoomId: string) {
  const resp = await apiGet<DealRoomResp>(`api/dealRooms/${dealRoomId}`);
  return resp.data;
}

export function useUpdateDealRoom(
  options: Partial<
    UseMutationOptions<
      DealRoomResp,
      unknown,
      { payload: DealRoomReq; dealRoomId: string }
    >
  > = {},
) {
  const { onSuccess, ...restOptions } = options;
  return useMutation<
    DealRoomResp,
    unknown,
    { payload: DealRoomReq; dealRoomId: string }
  >({
    mutationFn: ({ payload, dealRoomId }) =>
      apiPut<DealRoomResp>(`/api/dealRooms/${dealRoomId}`, payload).then(
        ({ data }) => data,
      ),
    onSuccess: (data, variables, context) => {},
    ...restOptions,
  });
}

export const doUploadAttachment = async (dealRoomId: string, file: File) => {
  const formData = new FormData();
  formData.append('file', file);

  const res = await apiUpload<IStorage>(
    `/api/dealRooms/${dealRoomId}/attachments`,
    formData,
  );
  return res.data;
};

export function useGetAttachmentByStorageId(
  dealRoomId: string,
  storageId: string,
  options: Partial<UseQueryOptions<IStorage>> = {},
) {
  return useQuery({
    queryKey: [...dealRoomServiceKeys.attachmentById(dealRoomId, storageId)],
    queryFn: () =>
      apiGet<IStorage>(
        `/api/dealRooms/${dealRoomId}/attachments/${storageId}/?attachContent=true`,
      ).then((res) => res.data),
    enabled: !!dealRoomId,
    refetchOnWindowFocus: false,
    ...options,
  });
}

export function useSaveDealRoomContacts(
  options: Partial<
    UseMutationOptions<
      DealRoomContactsResp,
      unknown,
      { payload: DealRoomContactsReq; dealRoomId: string }
    >
  > = {},
) {
  const { onSuccess, ...restOptions } = options;
  return useMutation<
    DealRoomContactsResp,
    unknown,
    { payload: DealRoomContactsReq; dealRoomId: string }
  >({
    mutationFn: ({ payload, dealRoomId }) =>
      apiPut<any>(`/api/dealRooms/${dealRoomId}/contacts`, payload).then(
        ({ data }) => data,
      ),
    onSuccess: (data, variables, context) => {},
    ...restOptions,
  });
}
