import {
  AddressSchema,
  LegalEntitySnapshot,
  QuoteBasicRespSchema,
  SigningOrderEnumZ,
} from '@monetize/types/app';
import { boolean, string, z } from 'zod';
import { getRequiredMessage } from '../utils/messages';
import { ContactRespSchema } from './contactTypes';
import { OpportunityRespSchema } from './opportunityTypes';

export const DealRoomSettingSchema = z.object({
  enabled: z.boolean(),
  useDefaultWelcomeMessage: z.boolean(),
  defaultWelcomeMessage: z.string().nullish(),
  defaultWelcomeUrl: z.string().url().nullish(),
  canEndCustomerChangeSigningContact: z.object({
    canChange: z.boolean(),
    canCreate: z.boolean(),
  }),
});

export type IDealRoomSettingSchema = z.infer<typeof DealRoomSettingSchema>;

export const DealRoomSettingWelcomeSchema = z.object({
  useDefaultWelcomeMessage: z.boolean(),
  defaultWelcomeMessage: z.string().nullish(),
  defaultWelcomeUrl: z.string().url().or(z.literal('')).nullable().optional(),
  defaultWelcomeUrlLabel: z.string().nullish(),
});

export type IDealRoomSettingWelcomeSchema = z.infer<
  typeof DealRoomSettingWelcomeSchema
>;

export enum DealRoomStatusEnum {
  DRAFT = 'DRAFT',
  SHARED = 'SHARED',
  IN_REVIEW = 'IN_REVIEW',
  DECLINED = 'DECLINED',
  SIGNED = 'SIGNED',
  EXPIRED = 'EXPIRED',
  CANCELED = 'CANCELED',
}
export const DealRoomStatusEnumSchemaZ = z.nativeEnum(DealRoomStatusEnum);

export enum DealRoomDocumentSectionKeyEnum {
  WELCOME = 'WELCOME',
  FEATURES = 'FEATURES',
  EXTRA_INFO = 'EXTRA_INFO',
}
export const DealRoomDocumentSectionKeyEnumZ = z.nativeEnum(
  DealRoomDocumentSectionKeyEnum,
);

export const DEAL_ROOM_WELCOME_MESSAGE_CONTENT_KEY = 'WELCOME_MESSAGE';
export const DEAL_ROOM_WELCOME_URL_CONTENT_KEY = 'WELCOME_URL';

export const DealRoomTextContentSchema = z.object({
  key: z.string(),
  contentType: z.literal('TEXT'),
  name: z.string().nullish(),
  description: z.string().nullish(),
  content: z.string(),
});
export type DealRoomTextContent = z.infer<typeof DealRoomTextContentSchema>;
export const DealRoomUrlContentSchema = z.object({
  key: z.string(),
  contentType: z.literal('URL'),
  name: z.string().nullish(),
  description: z.string().nullish(),
  content: z.object({
    link: z.string().url().nonempty(getRequiredMessage('Link')),
    label: z.string().nullish(),
  }),
});
export type DealRoomUrlContent = z.infer<typeof DealRoomUrlContentSchema>;

// export const DealRoomListContentSchema = z.object({
//   key: z.string(),
//   contentType: z.literal('LIST'),
//   content: z.array(z.lazy(() => DealRoomContentSchema)),
// });

export const DealRoomAttachmentContentSchema = z.object({
  key: z.string(),
  contentType: z.literal('ATTACHMENT'),
  name: z.string().nullish(),
  description: z.string().nullish(),
  content: z.object({
    storageObjectId: z.string(),
  }),
});
export type DealRoomAttachmentContent = z.infer<
  typeof DealRoomAttachmentContentSchema
>;

export const DealRoomDocumentSectionContentSchema = z.union([
  DealRoomTextContentSchema,
  DealRoomUrlContentSchema,
  // DealRoomListContentSchema,
  DealRoomAttachmentContentSchema,
]);

export const DealRoomDocumentSectionSchema = z.object({
  key: DealRoomDocumentSectionKeyEnumZ,
  title: z.string().optional(),
  description: z.string().nullish(),
  contents: z.array(DealRoomDocumentSectionContentSchema),
});
export type DealRoomDocumentSectionContent = z.infer<
  typeof DealRoomDocumentSectionContentSchema
>;

export type DealRoomDocumentSection = z.infer<
  typeof DealRoomDocumentSectionSchema
>;
export const DealRoomDocumentSchema = z.object({
  sections: z.array(DealRoomDocumentSectionSchema),
});
export const DealRoomQuoteRespSchema = z.object({
  quote: QuoteBasicRespSchema,
  name: z.string(),
  note: z.string().nullish(),
  position: z.number(),
});
export type DealRoomQuoteResp = z.infer<typeof DealRoomQuoteRespSchema>;

export const DealRoomContactRespSchema = z.object({
  snapshotId: string().nullish(),
  address: AddressSchema.nullish(),
  customId: string().nullable(),
  email: string(),
  fullName: string(),
  internal: boolean().nullish(),
  phone: string().nullable(),
  title: string().nullable(),
  id: string(),
});
export type IDealRoomContactRespSchema = z.infer<
  typeof DealRoomContactRespSchema
>;

export const DealRoomContactsRespSchema = z.object({
  additionalESignRecipients: z.array(DealRoomContactRespSchema),
  esign: z.array(ContactRespSchema.extend({ internal: z.boolean() })),
  billingContact: ContactRespSchema.nullish(),
  shippingContact: DealRoomContactRespSchema.nullish(),
});
export type DealRoomContactsResp = z.infer<typeof DealRoomContactsRespSchema>;

export const DealRoomRespSchema = z.object({
  id: z.string(),
  customId: z.string().optional(),
  expirationDate: z.string(),
  status: DealRoomStatusEnumSchemaZ,
  opportunity: OpportunityRespSchema,
  quotes: z.array(DealRoomQuoteRespSchema),
  fromCompany: LegalEntitySnapshot,
  owner: z.object({
    id: z.string(),
    name: z.string(),
    email: z.string(),
  }),
  purchaseOrderNumber: z.string().nullish(),
  requiresEsign: z.boolean(),
  selectedQuoteId: z.string().optional(),
  accountId: z.string(),
  accountName: z.string(),
  contacts: DealRoomContactsRespSchema,
  shippingAddress: AddressSchema.nullish(),
  billingAddress: AddressSchema.nullish(),
  signingOrder: SigningOrderEnumZ,
  document: z.object({
    version: z.string(),
    document: DealRoomDocumentSchema,
  }),
});
export type DealRoomResp = z.infer<typeof DealRoomRespSchema>;

export const DealRoomQuoteItemReqSchema = z.object({
  quoteId: z.string(),
  customName: z.string(),
  note: z.string().nullish(),
  position: z.number(),
});
export type DealRoomQuoteItemReq = z.infer<typeof DealRoomQuoteItemReqSchema>;

export const DealRoomContactsReqSchema = z.object({
  shippingId: z.string().nullish(),
  billingId: z.string().nullish(),
  esignCcIds: z.array(z.string()),
  esignIds: z.array(z.string()),
});
export type DealRoomContactsReq = z.infer<typeof DealRoomContactsReqSchema>;

export const DealRoomReqSchema = z.object({
  customId: z.string().nullish(),
  expirationDate: z.string(),
  quotes: z.array(DealRoomQuoteItemReqSchema),
  purchaseOrderNumber: z.string().nullish(),
  requiresEsign: z.boolean(),
  selectedQuoteId: z.string().nullish(),
  document: z.object({
    version: z.string(),
    document: DealRoomDocumentSchema,
  }),
  signingOrder: SigningOrderEnumZ,
});
export type DealRoomReq = z.infer<typeof DealRoomReqSchema>;

export const DealRoomWelcomeSchema = z.object({
  title: z.string().nonempty(getRequiredMessage('Title')),
  message: z.string().nonempty(getRequiredMessage('Introduction Message')),
  link: z.string().url().nonempty(getRequiredMessage('Link')),
  linkLabel: z.string().nullish(),
});
export type DealRoomWelcome = z.infer<typeof DealRoomWelcomeSchema>;

export type DealRoomQuoteSelectRow = {
  quoteId: string;
  position: number | null;
  selected: boolean;
};

export const DealRoomNextStepEsignContactSchema = z.object({
  requiresEsign: z.boolean(),
  signingOrder: SigningOrderEnumZ,

  internalEsignContactId: z.string().nullish(),
  externalEsignContactId: z.string().nullish(),
  esignCcContacts: z.array(DealRoomContactRespSchema),
});
export type DealRoomNextStepEsignContact = z.infer<
  typeof DealRoomNextStepEsignContactSchema
>;

export const DealRoomNextStepContactSchema = z.object({
  shippingId: z.string().nonempty(getRequiredMessage('Shipping Contact')),
  billingId: z.string().nonempty(getRequiredMessage('Billing Contact')),
  useSameShippingBilling: z.boolean(),
});
export type DealRoomNextStepContact = z.infer<
  typeof DealRoomNextStepContactSchema
>;
