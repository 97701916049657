import { usePrintQuoteToHtml } from '../../../../../../api/cpqService';
import { HtmlIFrameContentRenderer } from '../../../../../../components/Monetize/HtmlIFrameContentRenderer';

interface QuoteOptionSelectHtmlProps {
  quoteId: string;
}

export const QuoteOptionSelectHtml = ({
  quoteId,
}: QuoteOptionSelectHtmlProps) => {
  const { data, isLoading, isRefetching, error } = usePrintQuoteToHtml({
    quoteId: quoteId,
  });

  if (error) {
    // TODO: add error state
    return null;
  }

  return (
    <HtmlIFrameContentRenderer
      id="quote-content"
      html={data}
      isLoading={isLoading}
      isReFetching={isLoading || isRefetching}
    ></HtmlIFrameContentRenderer>
  );
};
