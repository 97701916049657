import { DEFAULT_PAGER, TDataTablePager } from '@monetize/types/common';
import { useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { handleApiErrorToast } from '../../api/axios';
import {
  useGetAccountContacts,
  useGetEsignContacts,
} from '../../api/contactsService';
import { EMPTY_ARRAY } from '../../constants/common';
import { useIsTenantEsignConfigured } from '../../hooks/useIsTenantEsignConfigured';
import { logger } from '../../services/logger';
import { IContactRespSchema } from '../../types';
import {
  DealRoomNextStepContact,
  DealRoomNextStepEsignContact,
  DealRoomReq,
  DealRoomResp,
} from '../../types/dealroomTypes';
import { filterInactiveContacts } from '../../utils';
import { useSaveDealRoomContacts } from '../api/dealRoomService';
import {
  getContactsFromDRResp,
  getDRReqContacts,
  getEsignContactsFromDRResp,
} from '../utils/dealRoomUtils';

const accountContactsPager: TDataTablePager = {
  ...DEFAULT_PAGER,
  sortField: 'fullName',
  sortOrder: 1,
  rows: 100,
};

export function useDealRoomContacts({
  accountId,
  dealRoomId,
  dealRoomResp,
  setDealRoomResp,
  onSubmitDealRoom,
  methods,
}: {
  accountId?: string;
  dealRoomId: string;
  dealRoomResp: DealRoomResp | null;
  setDealRoomResp: (dealRoomResp: DealRoomResp) => void;
  onSubmitDealRoom: (data: DealRoomReq) => Promise<void>;
  methods: UseFormReturn<DealRoomReq>;
}) {
  const [contacts, setContacts] = useState<DealRoomNextStepContact>(
    getContactsFromDRResp(dealRoomResp?.contacts),
  );
  const { getValues } = methods;

  const [esignContacts, setEsignContacts] =
    useState<DealRoomNextStepEsignContact>(
      getEsignContactsFromDRResp(dealRoomResp),
    );

  useEffect(() => {
    setContacts(getContactsFromDRResp(dealRoomResp?.contacts));
    setEsignContacts(getEsignContactsFromDRResp(dealRoomResp));
  }, [dealRoomResp]);

  const [isContactsSaving, setIsContactsSaving] = useState(false);
  const { mutateAsync: doSaveContacts } = useSaveDealRoomContacts();
  const { data: accountContacts, isLoading: loadingAccountContacts } =
    useGetAccountContacts(
      {
        accountId: accountId!,
        config: accountContactsPager,
        isGetAll: true,
      },
      {
        enabled: !!accountId,
        select: (response) => response?.content || EMPTY_ARRAY,
        refetchOnWindowFocus: false,
      },
    );

  const {
    allContacts: internalAccountEsignContacts,
    isLoading: loadingInternalEsignContacts,
  } = useGetEsignContacts({ accountId, refetchOnWindowFocus: false });

  const [externalAccountContacts, setExternalAccountContacts] = useState<
    IContactRespSchema[]
  >([]);
  const [internalAccountContacts, setInternalAccountContacts] = useState<
    IContactRespSchema[]
  >([]);

  useEffect(() => {
    const activeExternal = filterInactiveContacts(accountContacts || []);
    setExternalAccountContacts(activeExternal);
    setInternalAccountContacts(internalAccountEsignContacts || []);
  }, [accountContacts, internalAccountEsignContacts]);

  const isTenantEsignConfigured = useIsTenantEsignConfigured();
  const isEsignEnabled = isTenantEsignConfigured;

  const handleSaveContacts = async ({
    contactForm,
    esignForm,
  }: {
    contactForm?: DealRoomNextStepContact;
    esignForm?: DealRoomNextStepEsignContact;
  }) => {
    const newContactsReq = getDRReqContacts(
      contactForm || contacts,
      esignForm || esignContacts,
    );

    setIsContactsSaving(true);
    try {
      if (esignForm && dealRoomResp) {
        // Check if esignOrder and requiresEsign has changed
        if (
          esignForm.requiresEsign !== dealRoomResp?.requiresEsign ||
          esignForm.signingOrder !== dealRoomResp.signingOrder
        ) {
          // trigger save first to update requiresEsign and signingOrder
          onSubmitDealRoom({
            ...getValues(),
            requiresEsign: esignForm.requiresEsign,
            signingOrder: esignForm.signingOrder,
          });
        }
      }
      const newContacts = await doSaveContacts({
        payload: newContactsReq,
        dealRoomId: dealRoomId,
      });
      dealRoomResp &&
        setDealRoomResp({
          ...dealRoomResp,
          contacts: newContacts,
          requiresEsign:
            esignForm?.requiresEsign || esignContacts.requiresEsign,
          signingOrder: esignForm?.signingOrder || esignContacts.signingOrder,
        });
    } catch (err) {
      handleApiErrorToast(err);
      logger.log(err);
    } finally {
      setIsContactsSaving(false);
    }
  };

  return {
    accountContacts,
    loadingContacts: loadingAccountContacts || loadingInternalEsignContacts,
    isEsignEnabled,
    internalAccountEsignContacts,
    loadingInternalEsignContacts,
    externalAccountContacts,
    internalAccountContacts,
    handleSaveContacts,
    isContactsSaving,
    contacts,
    esignContacts,
  };
}
