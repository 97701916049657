import { Progress } from '@chakra-ui/react';
import {
  MBox,
  MFlex,
  MText,
  MVStack,
} from '../../../../../components/Monetize';
import { MEntityStatusBadge } from '../../../../../components/Monetize/MEntityStatusBadge';
import { DEAL_ROOM_STATUS_LIST } from '../../../../constants/dealroom';
import { useDealRoomContext } from '../dealRoomContext';
import { NotificationItem } from './NotificationItem';

interface DealRoomRightSideBarProps {}

export const DealRoomRightSideBar: React.FC<
  DealRoomRightSideBarProps
> = ({}) => {
  const { dealRoomResp, isSidebarExpand, setSidebarExpand } =
    useDealRoomContext();
  const foundStatus = DEAL_ROOM_STATUS_LIST.find(
    ({ status }) => status === dealRoomResp?.status,
  );

  if (!foundStatus) {
    return null;
  }

  return (
    <MBox bg="tPurple.500" h="full" w="full" p="4" minWidth="250px">
      <MFlex mt="2">
        {/* <MCustomIconButton
          icon={isSidebarExpand ? MdKeyboardArrowRight : MdKeyboardArrowLeft}
          iconColor="tWhite.base"
          iconProps={{ w: 6, h: 6 }}
          marginLeft={-1}
          onClick={() => setSidebarExpand(!isSidebarExpand)}
        /> */}
        {isSidebarExpand && (
          <MBox w="full">
            <MBox
              p={2}
              border="1px solid"
              borderColor="tBlue.100"
              borderRadius={4}
            >
              <MFlex justifyContent="space-between" alignItems="center">
                <MText color="tWhite.base" fontSize="sm">
                  Deal Status:
                </MText>
                <MFlex
                  justifyContent="center"
                  alignItems="center"
                  p={1}
                  background="tBlue.100"
                  borderRadius={4}
                >
                  <MEntityStatusBadge status={foundStatus?.status} mx="0" />
                </MFlex>
              </MFlex>
              <Progress
                colorScheme="tIndigo"
                size="sm"
                value={
                  (foundStatus?.order / DEAL_ROOM_STATUS_LIST.length) * 100
                }
                mt={2}
              />
            </MBox>
            <MText
              color="tWhite.base"
              fontWeight="bold"
              fontSize="md"
              mt="8"
              mb="4"
            >
              Notifications
            </MText>

            <MVStack spacing="2">
              <NotificationItem />
              <NotificationItem />
            </MVStack>
          </MBox>
        )}
      </MFlex>
    </MBox>
  );
};
