import { Controller } from 'react-hook-form';
import { MdShare } from 'react-icons/md';
import { useNavigate } from 'react-router';
import {
  MBox,
  MButton,
  MFlex,
  MFormField,
  MIcon,
  MText,
} from '../../../../../components/Monetize';
import { DatePicker } from '../../../../../components/Monetize/DatePicker/DatePicker';
import { MBackButton } from '../../../../../components/Monetize/MBackButton';
import { MLastSavedStatus } from '../../../../../components/Monetize/MLastSavedStatus';
import { useDealRoomContext } from '../dealRoomContext';

interface DealRoomTopBarProps {}
export const DealRoomTopBar: React.FC<DealRoomTopBarProps> = ({}) => {
  const {
    dealRoomResp,
    methods,
    handleSubmitButton,
    isDRSaving,
    isDRSavingErrored,
    isDealRoomReadOnly,
  } = useDealRoomContext();

  const {
    control,
    formState: { errors },
  } = methods;

  const navigate = useNavigate();

  return (
    <MBox
      height="64px"
      background="tBlue.100"
      display="flex"
      alignItems="center"
      position="sticky"
      top="0"
      zIndex="10"
    >
      <MBox
        width="100%"
        maxWidth="1440px"
        margin="auto"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        px="4"
      >
        <MFlex alignItems="center">
          <MBackButton onClick={() => navigate(-1)} />
          <MText color="tPurple.500" fontSize="lg" ml="2">
            Deal Room for:
          </MText>
          <MText
            color="tPurple.500"
            fontSize="lg"
            fontWeight="bold"
            ml="4"
            mr="4"
          >
            {dealRoomResp.fromCompany.name}
          </MText>
        </MFlex>

        <MFlex alignItems="center">
          {!isDealRoomReadOnly && (
            <MLastSavedStatus
              mr={2}
              isLoading={isDRSaving}
              isError={isDRSavingErrored}
            />
          )}
          <MFormField
            error={errors?.expirationDate}
            label="Expiration Date:"
            isHorizontal
            labelProps={{ fontWeight: 'normal' }}
          >
            <Controller
              name="expirationDate"
              control={control}
              render={({ field: { onChange, ...field } }) => (
                <DatePicker
                  {...field}
                  minDate={new Date()}
                  onChange={(val) => {
                    onChange(val);
                    handleSubmitButton();
                  }}
                />
              )}
            />
          </MFormField>
          <MButton ml="2" variant="primary" size="sm">
            <MIcon as={MdShare} mr="2" width={4} height={4} /> Share
          </MButton>
        </MFlex>
      </MBox>
    </MBox>
  );
};
