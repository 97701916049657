import { IContactRespSchema } from '@monetize/types/app';
import { forwardRef, useEffect, useState } from 'react';
import { contactSelectRenderItem } from '../../../../../../components/Contacts/ContactSelect';
import { MCustomSelect, MText } from '../../../../../../components/Monetize';
import { MainSearchInput } from '../../../../../../components/Monetize/MCustomSelect/components/MainSearchInput';
import { CONTACTS } from '../../../../../../constants';
import { MCustomSelectProps } from '../../../../../../types/mCustomSelectTypes';

interface NextStepsSectionContactInfoContactInputProps
  extends MCustomSelectProps {
  isDisabled?: boolean;
  contacts: IContactRespSchema[];
  loading?: boolean;
  onChange: (contactId: string) => void;
  onNewContact?: () => void;
  canCreateContact: boolean;
}

export const NextStepsSectionContactInfoContactInput = forwardRef(
  (
    {
      isDisabled,
      contacts,
      loading,
      onChange,
      onNewContact,
      canCreateContact,
      value,
      ...rest
    }: NextStepsSectionContactInfoContactInputProps,
    ref,
  ) => {
    const [query, setQuery] = useState('');
    const [iContacts, setIContacts] = useState(contacts);
    const onChangeQuery = (queryVal: string): void => {
      if (queryVal !== query) {
        setQuery(queryVal);
      }
    };

    useEffect(() => {
      if (query) {
        setIContacts(
          contacts.filter(
            ({ fullName, email }) =>
              fullName?.toLowerCase().includes(query.toLowerCase()) ||
              email?.toLowerCase().includes(query.toLowerCase()),
          ),
        );
      } else {
        setIContacts(contacts);
      }
    }, [contacts, query]);

    if (isDisabled && !value) {
      return <MText fontStyle="italic">Blank</MText>;
    }

    return (
      <MCustomSelect
        name="contacts"
        loading={loading}
        items={iContacts}
        itemTitle="fullName"
        itemValue="id"
        onChange={(ev: any) => onChange(ev as string)}
        useMainInputAsSearchInput
        MainInputComponent={MainSearchInput}
        useHighlightedItemList
        showItemDivider
        inputProps={{
          autoComplete: 'off',
        }}
        useExternalQuery
        externalQuery={query}
        onChangeQuery={onChangeQuery}
        skipFilter={true}
        prependItems={
          canCreateContact
            ? [
                {
                  item: CONTACTS.NEW_CONTACT,
                  isAction: true,
                  hasDivider: true,
                  onClick: ({ onClose }) => {
                    onClose && onClose();
                    onNewContact && onNewContact();
                  },
                },
              ]
            : []
        }
        renderItemContent={contactSelectRenderItem}
        isDisabled={isDisabled}
        value={value}
        {...rest}
      />
    );
  },
);
