import { zodResolver } from '@hookform/resolvers/zod';
import { QuoteStatusEnum } from '@monetize/types/app';
import debounce from 'lodash/debounce';
import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { FormProvider, useForm, UseFormReturn } from 'react-hook-form';
import { useGetOpportunityById } from '../../../../api/cpqService';
import { logger } from '../../../../services/logger';
import { useToast } from '../../../../services/toast';
import {
  DealRoomReq,
  DealRoomReqSchema,
  DealRoomResp,
} from '../../../../types/dealroomTypes';
import { DEAL_ROOM_EDITABLE_STATUSES } from '../../../constants/dealroom';
import { useDealRoomContacts } from '../../../hooks/useDealRoomContacts';
import { getDealRoomReqFromResp } from '../../../utils/dealRoomUtils';
import { useDealRoom } from './useDealRoom';

type DealRoomContextTypes = {
  methods: UseFormReturn<DealRoomReq, object>;
  handleSubmitButton: () => Promise<void>;
  handleSubmitButtonWithoutDirtyCheck: () => void;
  dealRoomResp: DealRoomResp;
  isDRInitialLoading: boolean;
  isDRSaving: boolean;
  isDRSavingErrored: boolean;
  isDealRoomReadOnly: boolean;
  /** Directly update DealRoom without form */
  onSubmitDealRoom: (data: DealRoomReq) => Promise<void>;
  dealRoomContacts: ReturnType<typeof useDealRoomContacts>;
  isSidebarExpand: boolean;
  setSidebarExpand: Dispatch<SetStateAction<boolean>>;
  opportunityWithQuotes: ReturnType<typeof useGetOpportunityById>;
};

const initialContext = {};

export const DealRoomContext = createContext<DealRoomContextTypes>(
  initialContext as any,
);

export const useDealRoomContext = () => {
  const context = useContext(DealRoomContext);
  if (!context) {
    throw new Error(
      `useDealRoomContext must be used rendered within the DealRoomContextProvider`,
    );
  }
  return context;
};

interface DealRoomContextProviderProps {
  children: React.ReactNode;
  dealRoomId: string;
}

export const DealRoomContextProvider: FC<DealRoomContextProviderProps> = ({
  children,
  dealRoomId,
}) => {
  const {
    dealRoomResp,
    isInitialLoading: isDRInitialLoading,
    handleUpdateDealRoom,
    isDRSaving,
    isDRSavingErrored,
    setDealRoomResp,
  } = useDealRoom(dealRoomId);
  const [isDealRoomReadOnly, setIsDealRoomReadOnly] = useState<boolean>(false);
  const [isSidebarExpand, setSidebarExpand] = useState(true);

  const { addToast } = useToast();

  const methods = useForm<DealRoomReq>({
    mode: 'onChange',
    resolver: zodResolver(DealRoomReqSchema),
    defaultValues: getDealRoomReqFromResp(dealRoomResp),
    resetOptions: {
      keepDirtyValues: true,
      keepErrors: true,
    },
  });

  const opportunityWithQuotes = useGetOpportunityById(
    dealRoomResp?.opportunity?.id!,
    {
      enabled: !!dealRoomResp?.opportunity?.id,
      refetchOnWindowFocus: false,
    },
  );

  const { reset, handleSubmit } = methods;

  useEffect(() => {
    if (dealRoomResp) {
      reset(getDealRoomReqFromResp(dealRoomResp));

      setIsDealRoomReadOnly(
        !DEAL_ROOM_EDITABLE_STATUSES.has(dealRoomResp.status),
      );
    }
  }, [dealRoomResp]);

  const onSubmitDealRoom = async (data: DealRoomReq) => {
    const draftQuotes = data.quotes.filter(
      ({ quoteId }) =>
        (opportunityWithQuotes?.data?.quotes || []).find(
          ({ id }) => id === quoteId,
        )?.status === QuoteStatusEnum.DRAFT,
    );
    const selectedQuoteId =
      (opportunityWithQuotes?.data?.quotes || []).find(
        ({ id }) => id === data.selectedQuoteId,
      )?.status === QuoteStatusEnum.DRAFT
        ? data.selectedQuoteId
        : null;

    // Choose of non-draft quotes and non-draft selected quotes
    const newData = { ...data, quotes: draftQuotes, selectedQuoteId };

    // If it includes non-draft quotes, we need to show a toast and opt out non-draft quotes
    if (draftQuotes.length !== data.quotes.length) {
      addToast({
        summary: 'Notice',
        detail:
          'Non-Draft qutoes are automatically removed from the Deal Room.',
        severity: 'warning',
      });
      newData.quotes = draftQuotes;
    }

    await handleUpdateDealRoom(newData);
  };

  const dealRoomContacts = useDealRoomContacts({
    accountId: dealRoomResp?.accountId,
    dealRoomId,
    setDealRoomResp,
    dealRoomResp,
    onSubmitDealRoom,
    methods,
  });

  const onError = (error: any, e: any) => {
    // Make sure we see this log when it fails
    logger.error(error);
  };

  const handleSubmitButton = handleSubmit(onSubmitDealRoom, onError);
  const handleSubmitButtonWithoutDirtyCheck = debounce(
    () => handleSubmit(onSubmitDealRoom, onError)(),
    300,
  );

  const providerValue: DealRoomContextTypes = {
    methods,
    handleSubmitButton,
    handleSubmitButtonWithoutDirtyCheck,
    dealRoomResp: dealRoomResp as DealRoomResp,
    isDRInitialLoading,
    isDRSaving,
    isDRSavingErrored,
    isDealRoomReadOnly,
    onSubmitDealRoom,
    dealRoomContacts,
    isSidebarExpand,
    setSidebarExpand,
    opportunityWithQuotes,
  };

  return (
    <DealRoomContext.Provider value={providerValue}>
      <FormProvider {...methods}>{children}</FormProvider>
    </DealRoomContext.Provider>
  );
};
