/* eslint-disable prefer-const */
import {
  Box,
  Button,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Spinner,
  Text,
  Wrap,
} from '@chakra-ui/react';
import React from 'react';
import { MdArrowDropDown, MdClose, MdSearch } from 'react-icons/md';
import { pluralize } from '../../../../utils';
import MCustomIconButton from '../../MCustomIconButton';
import { MainInputProps } from './MainInput';
import { useCustomSelectInput } from './useCustomSelectInput';

export const MainSearchInput = React.forwardRef<
  HTMLInputElement,
  MainInputProps
>((props, ref) => {
  const {
    disabled,
    externalLoading,
    getTitleFromValue,
    inputProps,
    isOpen,
    isReadOnly,
    isSubtitleItem,
    items,
    loading,
    onOpen,
    onQueryChange,
    placeholder,
    query,
    renderContentInInput,
    showContentInInput,
    value,
  } = props;

  let {
    bgColor,
    borderColor,
    handleFocusInput,
    handleOnClear,
    handleToggleOpen,
    inputValue,
    rightElement,
    rightMargin,
    showClearBtn,
    styles,
  } = useCustomSelectInput(props, ref);

  if (!!query && !loading) {
    rightMargin += 14;
  }

  const searchResultsCount =
    items?.filter((item) => !isSubtitleItem(item)).length || 0;

  if (!showContentInInput) {
    return (
      <InputGroup>
        {!isReadOnly && (
          <InputLeftElement pointerEvents="none" w="8">
            <Icon w="4" h="4" as={MdSearch} color="tPurple.base" />
          </InputLeftElement>
        )}
        <Input
          type="text"
          ref={ref}
          placeholder={placeholder}
          onChange={(e: any) => {
            !isOpen && onOpen(); // open if required
            onQueryChange(e.target.value);
          }}
          value={inputValue}
          onClick={() => !disabled && onOpen()}
          isDisabled={disabled}
          isReadOnly={isReadOnly}
          variant={isReadOnly ? 'readonly' : 'primary'}
          bg={bgColor}
          borderColor={isOpen ? 'tBlue.lightShade' : 'tGray.lightPurple'}
          color={isOpen ? 'tIndigo.base' : 'tPurple.dark'}
          tabIndex={isOpen ? -1 : 0}
          pr={`${rightMargin * 4}px`}
          _placeholder={{
            color: 'tGray.darkGrayPurple',
          }}
          cursor="pointer"
          title={inputValue}
          {...inputProps}
        />
        {!isReadOnly && (
          <InputRightElement width={`${rightMargin * 4}px`}>
            {rightElement?.content}
            {!!query && !loading && (
              <Text fontSize="xs" mr="2" whiteSpace="nowrap">
                {searchResultsCount
                  ? `${searchResultsCount} ${pluralize(
                      'Result',
                      searchResultsCount,
                    )}`
                  : 'No Results'}
              </Text>
            )}
            {showClearBtn && (
              <MCustomIconButton
                icon={MdClose}
                bg="none"
                color="tPurple.dark"
                _hover={{ bg: 'none' }}
                ml="2"
                btnSize={4}
                padding={0}
                containerSize={4}
                onClick={handleOnClear}
              />
            )}
            {loading || externalLoading ? (
              <Spinner size="xs" />
            ) : (
              <Button
                role="button"
                variant="icon"
                padding="0"
                minWidth="initial"
                _hover={{ backgroundColor: 'none' }}
                _focus={{ background: 'none', boxShadow: 'none' }}
                tabIndex={-1}
              >
                <Icon
                  as={MdArrowDropDown}
                  fontSize="20"
                  onClick={handleToggleOpen}
                  zIndex="1"
                />
              </Button>
            )}
          </InputRightElement>
        )}
      </InputGroup>
    );
  }
  return (
    <InputGroup
      __css={(styles as any).field}
      bg={bgColor}
      borderColor={borderColor}
      display="flex"
      flexWrap="wrap"
      height="auto"
      position="relative"
      alignItems="center"
      px="1"
    >
      <Wrap spacing={0.5}>
        {showContentInInput &&
          renderContentInInput &&
          renderContentInInput({ onToggleOpen: handleToggleOpen })}
        <Flex alignItems="center" flex="1" display="grid">
          <Input
            type="text"
            ref={ref}
            placeholder={placeholder}
            onChange={() => {}}
            value={getTitleFromValue(value) || ''}
            onFocus={handleFocusInput}
            isDisabled={disabled}
            tabIndex={isOpen ? -1 : 0}
            border="none"
            _focus={{ border: 'none' }}
            width="auto"
            bg="none"
            _placeholder={{
              color: 'tGray.darkGrayPurple',
            }}
            {...inputProps}
          />
        </Flex>
      </Wrap>
      <Box onClick={handleToggleOpen} position="absolute" right="0" bottom="1">
        {loading || externalLoading ? (
          <Spinner size="xs" />
        ) : (
          <Icon as={MdArrowDropDown} fontSize="20" zIndex="1" />
        )}
      </Box>
    </InputGroup>
  );
});
