import type { Identifier, XYCoord } from 'dnd-core';
import { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { logger } from '../../services/logger';

interface DragItem<RowDataType> {
  id: string;
  index: number;
  rowData: RowDataType;
}

interface MDraggableProps<RowDataType> {
  index: number;
  rowData: RowDataType;
  moveRow: (dragIndex: number, hoverIndex: number) => void;
  saveMoveChanges: () => void;
  acceptType: string;
}

export const useMDraggable = <RowDataType,>({
  index,
  rowData,
  moveRow,
  saveMoveChanges,
  acceptType,
}: MDraggableProps<RowDataType>) => {
  const dropContainerRef = useRef<any>(null);
  const dragHandleRef = useRef<any>(null);
  const [{ handlerId }, drop] = useDrop<
    DragItem<RowDataType>,
    void,
    { handlerId: Identifier | null }
  >({
    accept: acceptType,
    options: {},
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    drop(item) {
      saveMoveChanges();
    },
    hover(item: DragItem<RowDataType>, monitor) {
      try {
        if (!dropContainerRef.current) {
          return;
        }
        const dragIndex = item.index;
        const hoverIndex = index;

        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
          return;
        }

        // Determine rectangle on screen
        const hoverBoundingRect =
          dropContainerRef.current?.getBoundingClientRect();

        // Get vertical middle
        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

        // Determine mouse position
        const clientOffset = monitor.getClientOffset();

        // Get pixels to the top
        const hoverClientY =
          (clientOffset as XYCoord).y - hoverBoundingRect.top;

        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%

        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return;
        }

        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return;
        }

        // Time to actually perform the action
        moveRow(dragIndex, hoverIndex);

        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        item.index = hoverIndex;
      } catch (err) {
        logger.log(err);
      }
    },
  });

  const [{ isDragging }, drag, dragPreview] = useDrag({
    type: acceptType,
    item: () => {
      return { index, rowData };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(dragHandleRef);
  drop(dragPreview(dropContainerRef));

  const opacity = isDragging ? 0.3 : 1;

  return {
    dragHandleRef,
    dropContainerRef,
    handlerId,
    drag,
    opacity,
  };
};
