import { MBox, MPageLoader } from '../../../../components/Monetize';
import { DealRoomContainer } from './DealRoomContainer/DealRoomContainer';
import { useDealRoomContext } from './dealRoomContext';
import { DealRoomRightSideBar } from './DealRoomRightSideBar/DealRoomRightSideBar';
import { DealRoomSideBar } from './DealRoomSideBar/DealRoomSideBar';

const LEFT_SIDEBAR_WIDTH = '210px';
const RIGHT_SIDEBAR_WIDTH = '250px';
export const InternalDealRoomPageContent = () => {
  const { isDRInitialLoading, isSidebarExpand } = useDealRoomContext();
  if (isDRInitialLoading) {
    return <MPageLoader />;
  }

  const rightSideWidth = isSidebarExpand ? '250px' : '50px';
  return (
    <MBox>
      <MBox
        position="fixed"
        width={LEFT_SIDEBAR_WIDTH}
        display={['none', 'none', 'none', 'block']}
        maxW={LEFT_SIDEBAR_WIDTH}
        height="full"
      >
        <DealRoomSideBar />
      </MBox>

      <MBox
        marginLeft={[0, 0, 0, LEFT_SIDEBAR_WIDTH]}
        marginRight={[0, 0, 0, rightSideWidth]}
        transition="margin-inline 0.2s"
        overflowX="clip"
      >
        <DealRoomContainer />
      </MBox>

      <MBox
        position="fixed"
        width={rightSideWidth}
        display={['none', 'none', 'none', 'block']}
        maxW={rightSideWidth}
        right="0"
        top="0"
        height="100vh"
        transition="width 0.2s"
      >
        <DealRoomRightSideBar />
      </MBox>
    </MBox>
  );
};
