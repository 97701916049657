import { DealRoomStatusEnum } from '../../types/dealroomTypes';

export const DEAL_ROOM_SECTION_IDS = {
  DR_WELCOME: 'dr-welcome',
  DR_FEATURE_CASE: 'dr-feature-case',
  DR_QUOTE_OPTION: 'dr-quote-option',
  DR_EXTRA_INFO: 'dr-extra-info',
  DR_NEXT_STEPS: 'dr-next-steps',
};

export const DEAL_ROOM_EDITABLE_STATUSES = new Set([DealRoomStatusEnum.DRAFT]);

// Ordered list of deal room statuses
export const DEAL_ROOM_STATUS_LIST = [
  { status: DealRoomStatusEnum.DRAFT, label: 'Draft', order: 1 },
  { status: DealRoomStatusEnum.SHARED, label: 'Shared', order: 2 },
  { status: DealRoomStatusEnum.IN_REVIEW, label: 'In Review', order: 3 },
  { status: DealRoomStatusEnum.DECLINED, label: 'Declined', order: 4 },
  { status: DealRoomStatusEnum.SIGNED, label: 'Signed', order: 5 },
  { status: DealRoomStatusEnum.EXPIRED, label: 'Expired', order: 6 },
  { status: DealRoomStatusEnum.CANCELED, label: 'Canceled', order: 7 },
];
