import { BoxProps } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { Controller, useForm, useFormContext } from 'react-hook-form';
import { MdLink } from 'react-icons/md';
import {
  MBox,
  MFlex,
  MFormField,
  MIcon,
  MInput,
  MLink,
  MText,
} from '../../../../../components/Monetize';
import { MClipboardCopyBox } from '../../../../../components/Monetize/MClipboardCopyBox';
import {
  DealRoomReq,
  DealRoomUrlContent,
  DealRoomUrlContentSchema,
} from '../../../../../types/dealroomTypes';
import { useDealRoomContext } from '../dealRoomContext';
import { useDRSectionEditMode } from '../useDRSectionEditMode';

interface UrlContentProps extends BoxProps {
  currentContent: DealRoomUrlContent;
  handleDeleteContent: (content: DealRoomUrlContent) => void;
  setCurrentContent: (content: DealRoomUrlContent | null) => void;
  handleSaveContent: (content: DealRoomUrlContent) => void;
  isCreating?: boolean;
}
export const UrlContent: React.FC<UrlContentProps> = ({
  currentContent,
  handleDeleteContent,
  setCurrentContent,
  isCreating,
  handleSaveContent,
  ...rest
}: UrlContentProps) => {
  const { handleSubmitButton, isDealRoomReadOnly, dealRoomResp, isDRSaving } =
    useDealRoomContext();
  const formContext = useFormContext<DealRoomReq>();

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<DealRoomUrlContent>({
    resolver: zodResolver(DealRoomUrlContentSchema),
    mode: 'onBlur',
    defaultValues: { ...currentContent },
  });

  const onSubmit = async (data: DealRoomUrlContent) => {
    await handleSaveContent(data);
    setIsEditing(false);
  };

  const { iconGroups, isEditing, setIsEditing } = useDRSectionEditMode({
    isReadOnly: isDealRoomReadOnly,
    isLoading: isDRSaving,
    handleCancel: () => {
      reset(currentContent);
      if (isCreating) {
        setCurrentContent(null);
      }
      setIsEditing(false);
    },
    showDeletedButton: true,
    handleDelete: async () => {
      await handleDeleteContent(currentContent);
      setCurrentContent(null);
    },
  });
  useEffect(() => {
    setIsEditing(isCreating || false);
  }, [isCreating]);

  useEffect(() => {
    if (!isEditing) {
      reset(currentContent);
    }
  }, [currentContent, isEditing]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {isEditing && (
        <MBox flex="1" {...rest}>
          <MFormField label="Item Title" error={errors?.name} isRequired>
            <Controller
              name={`name`}
              control={control}
              render={({ field: { value, ...field } }) => (
                <MInput value={value || ''} {...field} />
              )}
            />
          </MFormField>

          <MFormField
            mt={4}
            label="Item Description"
            error={errors?.description}
            isRequired
          >
            <Controller
              name={`description`}
              control={control}
              render={({ field: { value, ...field } }) => (
                <MInput value={value || ''} {...field} />
              )}
            />
          </MFormField>

          <MFormField
            mt={4}
            label="Link"
            error={errors?.content?.link}
            isRequired
          >
            <Controller
              name={`content.link`}
              control={control}
              render={({ field: { value, ...field } }) => (
                <MInput value={value || ''} {...field} placeholder="https://" />
              )}
            />
          </MFormField>

          <MFormField
            mt={4}
            label="Link Label (optional)"
            error={errors?.content?.label}
          >
            <Controller
              name={`content.label`}
              control={control}
              render={({ field: { value, ...field } }) => (
                <MInput value={value || ''} {...field} />
              )}
            />
          </MFormField>

          <MFlex mt={4} justifyContent={'space-between'}>
            {iconGroups.saveIcon}
          </MFlex>
        </MBox>
      )}

      {!isEditing && (
        <MBox>
          <MFlex alignItems="center">
            <MText fontSize={'xl'} fontWeight="500" mr="4">
              {currentContent.name || ''}
            </MText>
            <MFlex alignItems={'center'} gap={4}>
              {iconGroups.editIcon}
            </MFlex>
          </MFlex>

          <MText mt={2}>{currentContent.description || ''}</MText>

          <MFlex alignItems="center">
            <MLink
              fontSize="sm"
              fontWeight="normal"
              href={currentContent.content?.link}
              target={'_blank'}
              display="flex"
              alignItems="center"
            >
              <MText color="tIndigo.base">
                {currentContent.content?.label || currentContent.content?.link}
              </MText>
            </MLink>
            <MClipboardCopyBox
              copyValue={currentContent.content?.link}
              tooltipMessage="Copy URL"
              renderContent={() => {
                return (
                  <MIcon
                    verticalAlign="middle"
                    as={MdLink}
                    boxSize={4}
                    color="tPurple.dark"
                    cursor="pointer"
                    ml="2"
                  />
                );
              }}
            />
          </MFlex>
        </MBox>
      )}
    </form>
  );
};
