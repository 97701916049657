import { z } from 'zod';
import { OpportunityStatusEnumZ } from './opportunitySharedTypes';
import { OpportunityQuoteRespSchema } from './quoteTypes';

// from GET /api/opportunities
export const OpportunityRespSchema = z.object({
  id: z.string(),
  name: z.string(),
  customId: z.string().nullable().optional(),
  primaryQuote: z.object({ id: z.string(), name: z.string() }).nullish(),
  lastModifiedBy: z.string(),
  modifyDate: z.string(),
  amount: z.number(),
  currency: z.string(),
  dealRoomId: z.string().nullish(),
});
export type IOpportunity = z.infer<typeof OpportunityRespSchema>;

// GET api/opportunities/{id}
export const OpportunityWithQuotesRespSchema = z.object({
  id: z.string(),
  accountId: z.string(),
  accountName: z.string(),
  customId: z.string(),
  status: OpportunityStatusEnumZ,
  amount: z.number(),
  name: z.string(),
  primaryQuoteId: z.string(),
  url: z.string().nullable(),
  owner: z.string(), // userId
  quotes: OpportunityQuoteRespSchema.array().nullish(),
  currency: z.string(),
});
export type IOpportunityWithQuotes = z.infer<
  typeof OpportunityWithQuotesRespSchema
>;

// GET api/opportunities/{id}
export const OpportunityReqSchema = z.object({
  name: z.string().nullish(),
  customId: z.string().nullish(),
  accountId: z.string(),
  primaryQuoteId: z.string().nullish(),
});
export type IOpportunityReqSchema = z.infer<typeof OpportunityReqSchema>;

export type CrmOpportunityForInput = { id: string; name: string };
